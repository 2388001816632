import * as Types from './generated';

import * as Operations from './generated';
import { NextRouter, useRouter } from 'next/router'
import { QueryHookOptions, useQuery } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type React from 'react';
import { getApolloClient , ApolloClientContext} from '@patron/data-access';















export async function getServerPageCbexCompetencesByAssessorId
    (options: Omit<Apollo.QueryOptions<Types.GQLCbexCompetencesByAssessorIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCbexCompetencesByAssessorIdQuery>({ ...options, query: Operations.CbexCompetencesByAssessorIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCbexCompetencesByAssessorId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCbexCompetencesByAssessorIdQuery, Types.GQLCbexCompetencesByAssessorIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CbexCompetencesByAssessorIdDocument, options);
};
export type PageCbexCompetencesByAssessorIdComp = React.FC<{data?: Types.GQLCbexCompetencesByAssessorIdQuery, error?: Apollo.ApolloError}>;
export const ssrCbexCompetencesByAssessorId = {
      getServerPage: getServerPageCbexCompetencesByAssessorId,
      
      usePage: useCbexCompetencesByAssessorId,
    }
export async function getServerPageCbexCompetenciesByCandidateIdAndOrganizationId
    (options: Omit<Apollo.QueryOptions<Types.GQLCbexCompetenciesByCandidateIdAndOrganizationIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCbexCompetenciesByCandidateIdAndOrganizationIdQuery>({ ...options, query: Operations.CbexCompetenciesByCandidateIdAndOrganizationIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCbexCompetenciesByCandidateIdAndOrganizationId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCbexCompetenciesByCandidateIdAndOrganizationIdQuery, Types.GQLCbexCompetenciesByCandidateIdAndOrganizationIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CbexCompetenciesByCandidateIdAndOrganizationIdDocument, options);
};
export type PageCbexCompetenciesByCandidateIdAndOrganizationIdComp = React.FC<{data?: Types.GQLCbexCompetenciesByCandidateIdAndOrganizationIdQuery, error?: Apollo.ApolloError}>;
export const ssrCbexCompetenciesByCandidateIdAndOrganizationId = {
      getServerPage: getServerPageCbexCompetenciesByCandidateIdAndOrganizationId,
      
      usePage: useCbexCompetenciesByCandidateIdAndOrganizationId,
    }
export async function getServerPageCbexCompetenciesNotInvoicedByOrganizationId
    (options: Omit<Apollo.QueryOptions<Types.GQLCbexCompetenciesNotInvoicedByOrganizationIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCbexCompetenciesNotInvoicedByOrganizationIdQuery>({ ...options, query: Operations.CbexCompetenciesNotInvoicedByOrganizationIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCbexCompetenciesNotInvoicedByOrganizationId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCbexCompetenciesNotInvoicedByOrganizationIdQuery, Types.GQLCbexCompetenciesNotInvoicedByOrganizationIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CbexCompetenciesNotInvoicedByOrganizationIdDocument, options);
};
export type PageCbexCompetenciesNotInvoicedByOrganizationIdComp = React.FC<{data?: Types.GQLCbexCompetenciesNotInvoicedByOrganizationIdQuery, error?: Apollo.ApolloError}>;
export const ssrCbexCompetenciesNotInvoicedByOrganizationId = {
      getServerPage: getServerPageCbexCompetenciesNotInvoicedByOrganizationId,
      
      usePage: useCbexCompetenciesNotInvoicedByOrganizationId,
    }
export async function getServerPageCbexCompetencyCandidatesByCompetencyIds
    (options: Omit<Apollo.QueryOptions<Types.GQLCbexCompetencyCandidatesByCompetencyIdsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCbexCompetencyCandidatesByCompetencyIdsQuery>({ ...options, query: Operations.CbexCompetencyCandidatesByCompetencyIdsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCbexCompetencyCandidatesByCompetencyIds = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCbexCompetencyCandidatesByCompetencyIdsQuery, Types.GQLCbexCompetencyCandidatesByCompetencyIdsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CbexCompetencyCandidatesByCompetencyIdsDocument, options);
};
export type PageCbexCompetencyCandidatesByCompetencyIdsComp = React.FC<{data?: Types.GQLCbexCompetencyCandidatesByCompetencyIdsQuery, error?: Apollo.ApolloError}>;
export const ssrCbexCompetencyCandidatesByCompetencyIds = {
      getServerPage: getServerPageCbexCompetencyCandidatesByCompetencyIds,
      
      usePage: useCbexCompetencyCandidatesByCompetencyIds,
    }
export async function getServerPageCbexCompetencyCandidatesByOrganizationId
    (options: Omit<Apollo.QueryOptions<Types.GQLCbexCompetencyCandidatesByOrganizationIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCbexCompetencyCandidatesByOrganizationIdQuery>({ ...options, query: Operations.CbexCompetencyCandidatesByOrganizationIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCbexCompetencyCandidatesByOrganizationId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCbexCompetencyCandidatesByOrganizationIdQuery, Types.GQLCbexCompetencyCandidatesByOrganizationIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CbexCompetencyCandidatesByOrganizationIdDocument, options);
};
export type PageCbexCompetencyCandidatesByOrganizationIdComp = React.FC<{data?: Types.GQLCbexCompetencyCandidatesByOrganizationIdQuery, error?: Apollo.ApolloError}>;
export const ssrCbexCompetencyCandidatesByOrganizationId = {
      getServerPage: getServerPageCbexCompetencyCandidatesByOrganizationId,
      
      usePage: useCbexCompetencyCandidatesByOrganizationId,
    }
export async function getServerPageCbexInvoiceDetails
    (options: Omit<Apollo.QueryOptions<Types.GQLCbexInvoiceDetailsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCbexInvoiceDetailsQuery>({ ...options, query: Operations.CbexInvoiceDetailsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCbexInvoiceDetails = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCbexInvoiceDetailsQuery, Types.GQLCbexInvoiceDetailsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CbexInvoiceDetailsDocument, options);
};
export type PageCbexInvoiceDetailsComp = React.FC<{data?: Types.GQLCbexInvoiceDetailsQuery, error?: Apollo.ApolloError}>;
export const ssrCbexInvoiceDetails = {
      getServerPage: getServerPageCbexInvoiceDetails,
      
      usePage: useCbexInvoiceDetails,
    }
export async function getServerPageCbexInvoicedHistory
    (options: Omit<Apollo.QueryOptions<Types.GQLCbexInvoicedHistoryQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCbexInvoicedHistoryQuery>({ ...options, query: Operations.CbexInvoicedHistoryDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCbexInvoicedHistory = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCbexInvoicedHistoryQuery, Types.GQLCbexInvoicedHistoryQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CbexInvoicedHistoryDocument, options);
};
export type PageCbexInvoicedHistoryComp = React.FC<{data?: Types.GQLCbexInvoicedHistoryQuery, error?: Apollo.ApolloError}>;
export const ssrCbexInvoicedHistory = {
      getServerPage: getServerPageCbexInvoicedHistory,
      
      usePage: useCbexInvoicedHistory,
    }
export async function getServerPageGetAddressesWithFilterOptions
    (options: Omit<Apollo.QueryOptions<Types.GQLGetAddressesWithFilterOptionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetAddressesWithFilterOptionsQuery>({ ...options, query: Operations.GetAddressesWithFilterOptionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetAddressesWithFilterOptions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetAddressesWithFilterOptionsQuery, Types.GQLGetAddressesWithFilterOptionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAddressesWithFilterOptionsDocument, options);
};
export type PageGetAddressesWithFilterOptionsComp = React.FC<{data?: Types.GQLGetAddressesWithFilterOptionsQuery, error?: Apollo.ApolloError}>;
export const ssrGetAddressesWithFilterOptions = {
      getServerPage: getServerPageGetAddressesWithFilterOptions,
      
      usePage: useGetAddressesWithFilterOptions,
    }
export async function getServerPageGetCbexCertificateDocumentData
    (options: Omit<Apollo.QueryOptions<Types.GQLGetCbexCertificateDocumentDataQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetCbexCertificateDocumentDataQuery>({ ...options, query: Operations.GetCbexCertificateDocumentDataDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetCbexCertificateDocumentData = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetCbexCertificateDocumentDataQuery, Types.GQLGetCbexCertificateDocumentDataQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetCbexCertificateDocumentDataDocument, options);
};
export type PageGetCbexCertificateDocumentDataComp = React.FC<{data?: Types.GQLGetCbexCertificateDocumentDataQuery, error?: Apollo.ApolloError}>;
export const ssrGetCbexCertificateDocumentData = {
      getServerPage: getServerPageGetCbexCertificateDocumentData,
      
      usePage: useGetCbexCertificateDocumentData,
    }
export async function getServerPageGetExamByMomentId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetExamByMomentIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetExamByMomentIdQuery>({ ...options, query: Operations.GetExamByMomentIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetExamByMomentId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetExamByMomentIdQuery, Types.GQLGetExamByMomentIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetExamByMomentIdDocument, options);
};
export type PageGetExamByMomentIdComp = React.FC<{data?: Types.GQLGetExamByMomentIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetExamByMomentId = {
      getServerPage: getServerPageGetExamByMomentId,
      
      usePage: useGetExamByMomentId,
    }
export async function getServerPageGetCbexExamDocumentData
    (options: Omit<Apollo.QueryOptions<Types.GQLGetCbexExamDocumentDataQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetCbexExamDocumentDataQuery>({ ...options, query: Operations.GetCbexExamDocumentDataDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetCbexExamDocumentData = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetCbexExamDocumentDataQuery, Types.GQLGetCbexExamDocumentDataQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetCbexExamDocumentDataDocument, options);
};
export type PageGetCbexExamDocumentDataComp = React.FC<{data?: Types.GQLGetCbexExamDocumentDataQuery, error?: Apollo.ApolloError}>;
export const ssrGetCbexExamDocumentData = {
      getServerPage: getServerPageGetCbexExamDocumentData,
      
      usePage: useGetCbexExamDocumentData,
    }
export async function getServerPageCbexExamResultByCandidateId
    (options: Omit<Apollo.QueryOptions<Types.GQLCbexExamResultByCandidateIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCbexExamResultByCandidateIdQuery>({ ...options, query: Operations.CbexExamResultByCandidateIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCbexExamResultByCandidateId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCbexExamResultByCandidateIdQuery, Types.GQLCbexExamResultByCandidateIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CbexExamResultByCandidateIdDocument, options);
};
export type PageCbexExamResultByCandidateIdComp = React.FC<{data?: Types.GQLCbexExamResultByCandidateIdQuery, error?: Apollo.ApolloError}>;
export const ssrCbexExamResultByCandidateId = {
      getServerPage: getServerPageCbexExamResultByCandidateId,
      
      usePage: useCbexExamResultByCandidateId,
    }
export async function getServerPageGetCbexMappedActivitiesByOrganizationId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetCbexMappedActivitiesByOrganizationIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetCbexMappedActivitiesByOrganizationIdQuery>({ ...options, query: Operations.GetCbexMappedActivitiesByOrganizationIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetCbexMappedActivitiesByOrganizationId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetCbexMappedActivitiesByOrganizationIdQuery, Types.GQLGetCbexMappedActivitiesByOrganizationIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetCbexMappedActivitiesByOrganizationIdDocument, options);
};
export type PageGetCbexMappedActivitiesByOrganizationIdComp = React.FC<{data?: Types.GQLGetCbexMappedActivitiesByOrganizationIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetCbexMappedActivitiesByOrganizationId = {
      getServerPage: getServerPageGetCbexMappedActivitiesByOrganizationId,
      
      usePage: useGetCbexMappedActivitiesByOrganizationId,
    }
export async function getServerPageGetCbexMappedResourceByOrganizationId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetCbexMappedResourceByOrganizationIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetCbexMappedResourceByOrganizationIdQuery>({ ...options, query: Operations.GetCbexMappedResourceByOrganizationIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetCbexMappedResourceByOrganizationId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetCbexMappedResourceByOrganizationIdQuery, Types.GQLGetCbexMappedResourceByOrganizationIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetCbexMappedResourceByOrganizationIdDocument, options);
};
export type PageGetCbexMappedResourceByOrganizationIdComp = React.FC<{data?: Types.GQLGetCbexMappedResourceByOrganizationIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetCbexMappedResourceByOrganizationId = {
      getServerPage: getServerPageGetCbexMappedResourceByOrganizationId,
      
      usePage: useGetCbexMappedResourceByOrganizationId,
    }
export async function getServerPageGetCbexOrganizationActivityResourceMapping
    (options: Omit<Apollo.QueryOptions<Types.GQLGetCbexOrganizationActivityResourceMappingQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetCbexOrganizationActivityResourceMappingQuery>({ ...options, query: Operations.GetCbexOrganizationActivityResourceMappingDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetCbexOrganizationActivityResourceMapping = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetCbexOrganizationActivityResourceMappingQuery, Types.GQLGetCbexOrganizationActivityResourceMappingQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetCbexOrganizationActivityResourceMappingDocument, options);
};
export type PageGetCbexOrganizationActivityResourceMappingComp = React.FC<{data?: Types.GQLGetCbexOrganizationActivityResourceMappingQuery, error?: Apollo.ApolloError}>;
export const ssrGetCbexOrganizationActivityResourceMapping = {
      getServerPage: getServerPageGetCbexOrganizationActivityResourceMapping,
      
      usePage: useGetCbexOrganizationActivityResourceMapping,
    }
export async function getServerPageGetCandidateByExamId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetCandidateByExamIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetCandidateByExamIdQuery>({ ...options, query: Operations.GetCandidateByExamIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetCandidateByExamId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetCandidateByExamIdQuery, Types.GQLGetCandidateByExamIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetCandidateByExamIdDocument, options);
};
export type PageGetCandidateByExamIdComp = React.FC<{data?: Types.GQLGetCandidateByExamIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetCandidateByExamId = {
      getServerPage: getServerPageGetCandidateByExamId,
      
      usePage: useGetCandidateByExamId,
    }
export async function getServerPageGetCandidateResultsByExamId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetCandidateResultsByExamIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetCandidateResultsByExamIdQuery>({ ...options, query: Operations.GetCandidateResultsByExamIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetCandidateResultsByExamId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetCandidateResultsByExamIdQuery, Types.GQLGetCandidateResultsByExamIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetCandidateResultsByExamIdDocument, options);
};
export type PageGetCandidateResultsByExamIdComp = React.FC<{data?: Types.GQLGetCandidateResultsByExamIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetCandidateResultsByExamId = {
      getServerPage: getServerPageGetCandidateResultsByExamId,
      
      usePage: useGetCandidateResultsByExamId,
    }
export async function getServerPageGetCanidatesByExamId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetCanidatesByExamIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetCanidatesByExamIdQuery>({ ...options, query: Operations.GetCanidatesByExamIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetCanidatesByExamId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetCanidatesByExamIdQuery, Types.GQLGetCanidatesByExamIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetCanidatesByExamIdDocument, options);
};
export type PageGetCanidatesByExamIdComp = React.FC<{data?: Types.GQLGetCanidatesByExamIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetCanidatesByExamId = {
      getServerPage: getServerPageGetCanidatesByExamId,
      
      usePage: useGetCanidatesByExamId,
    }
export async function getServerPageGetExamById
    (options: Omit<Apollo.QueryOptions<Types.GQLGetExamByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetExamByIdQuery>({ ...options, query: Operations.GetExamByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetExamById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetExamByIdQuery, Types.GQLGetExamByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetExamByIdDocument, options);
};
export type PageGetExamByIdComp = React.FC<{data?: Types.GQLGetExamByIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetExamById = {
      getServerPage: getServerPageGetExamById,
      
      usePage: useGetExamById,
    }
export async function getServerPageExamHistoryWithFilters
    (options: Omit<Apollo.QueryOptions<Types.GQLExamHistoryWithFiltersQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLExamHistoryWithFiltersQuery>({ ...options, query: Operations.ExamHistoryWithFiltersDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useExamHistoryWithFilters = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLExamHistoryWithFiltersQuery, Types.GQLExamHistoryWithFiltersQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ExamHistoryWithFiltersDocument, options);
};
export type PageExamHistoryWithFiltersComp = React.FC<{data?: Types.GQLExamHistoryWithFiltersQuery, error?: Apollo.ApolloError}>;
export const ssrExamHistoryWithFilters = {
      getServerPage: getServerPageExamHistoryWithFilters,
      
      usePage: useExamHistoryWithFilters,
    }
export async function getServerPageExamOverviewWithFilters
    (options: Omit<Apollo.QueryOptions<Types.GQLExamOverviewWithFiltersQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLExamOverviewWithFiltersQuery>({ ...options, query: Operations.ExamOverviewWithFiltersDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useExamOverviewWithFilters = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLExamOverviewWithFiltersQuery, Types.GQLExamOverviewWithFiltersQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ExamOverviewWithFiltersDocument, options);
};
export type PageExamOverviewWithFiltersComp = React.FC<{data?: Types.GQLExamOverviewWithFiltersQuery, error?: Apollo.ApolloError}>;
export const ssrExamOverviewWithFilters = {
      getServerPage: getServerPageExamOverviewWithFilters,
      
      usePage: useExamOverviewWithFilters,
    }
export async function getServerPageCbexMyExamsWithFilters
    (options: Omit<Apollo.QueryOptions<Types.GQLCbexMyExamsWithFiltersQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCbexMyExamsWithFiltersQuery>({ ...options, query: Operations.CbexMyExamsWithFiltersDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCbexMyExamsWithFilters = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCbexMyExamsWithFiltersQuery, Types.GQLCbexMyExamsWithFiltersQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CbexMyExamsWithFiltersDocument, options);
};
export type PageCbexMyExamsWithFiltersComp = React.FC<{data?: Types.GQLCbexMyExamsWithFiltersQuery, error?: Apollo.ApolloError}>;
export const ssrCbexMyExamsWithFilters = {
      getServerPage: getServerPageCbexMyExamsWithFilters,
      
      usePage: useCbexMyExamsWithFilters,
    }
export async function getServerPageGetNewCandidatesByMomentId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetNewCandidatesByMomentIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetNewCandidatesByMomentIdQuery>({ ...options, query: Operations.GetNewCandidatesByMomentIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetNewCandidatesByMomentId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetNewCandidatesByMomentIdQuery, Types.GQLGetNewCandidatesByMomentIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetNewCandidatesByMomentIdDocument, options);
};
export type PageGetNewCandidatesByMomentIdComp = React.FC<{data?: Types.GQLGetNewCandidatesByMomentIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetNewCandidatesByMomentId = {
      getServerPage: getServerPageGetNewCandidatesByMomentId,
      
      usePage: useGetNewCandidatesByMomentId,
    }
export async function getServerPageExamsNotInvoiced
    (options: Omit<Apollo.QueryOptions<Types.GQLExamsNotInvoicedQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLExamsNotInvoicedQuery>({ ...options, query: Operations.ExamsNotInvoicedDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useExamsNotInvoiced = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLExamsNotInvoicedQuery, Types.GQLExamsNotInvoicedQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ExamsNotInvoicedDocument, options);
};
export type PageExamsNotInvoicedComp = React.FC<{data?: Types.GQLExamsNotInvoicedQuery, error?: Apollo.ApolloError}>;
export const ssrExamsNotInvoiced = {
      getServerPage: getServerPageExamsNotInvoiced,
      
      usePage: useExamsNotInvoiced,
    }
export async function getServerPageGetOrganizationResourceMapping
    (options: Omit<Apollo.QueryOptions<Types.GQLGetOrganizationResourceMappingQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetOrganizationResourceMappingQuery>({ ...options, query: Operations.GetOrganizationResourceMappingDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetOrganizationResourceMapping = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetOrganizationResourceMappingQuery, Types.GQLGetOrganizationResourceMappingQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOrganizationResourceMappingDocument, options);
};
export type PageGetOrganizationResourceMappingComp = React.FC<{data?: Types.GQLGetOrganizationResourceMappingQuery, error?: Apollo.ApolloError}>;
export const ssrGetOrganizationResourceMapping = {
      getServerPage: getServerPageGetOrganizationResourceMapping,
      
      usePage: useGetOrganizationResourceMapping,
    }
export async function getServerPageNewExamOverviewWithFilterOptions
    (options: Omit<Apollo.QueryOptions<Types.GQLNewExamOverviewWithFilterOptionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLNewExamOverviewWithFilterOptionsQuery>({ ...options, query: Operations.NewExamOverviewWithFilterOptionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useNewExamOverviewWithFilterOptions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLNewExamOverviewWithFilterOptionsQuery, Types.GQLNewExamOverviewWithFilterOptionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.NewExamOverviewWithFilterOptionsDocument, options);
};
export type PageNewExamOverviewWithFilterOptionsComp = React.FC<{data?: Types.GQLNewExamOverviewWithFilterOptionsQuery, error?: Apollo.ApolloError}>;
export const ssrNewExamOverviewWithFilterOptions = {
      getServerPage: getServerPageNewExamOverviewWithFilterOptions,
      
      usePage: useNewExamOverviewWithFilterOptions,
    }

















export async function getServerPageDhsClientById
    (options: Omit<Apollo.QueryOptions<Types.GQLDhsClientByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLDhsClientByIdQuery>({ ...options, query: Operations.DhsClientByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useDhsClientById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLDhsClientByIdQuery, Types.GQLDhsClientByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.DhsClientByIdDocument, options);
};
export type PageDhsClientByIdComp = React.FC<{data?: Types.GQLDhsClientByIdQuery, error?: Apollo.ApolloError}>;
export const ssrDhsClientById = {
      getServerPage: getServerPageDhsClientById,
      
      usePage: useDhsClientById,
    }
export async function getServerPageDhsMortgageDossierMainById
    (options: Omit<Apollo.QueryOptions<Types.GQLDhsMortgageDossierMainByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLDhsMortgageDossierMainByIdQuery>({ ...options, query: Operations.DhsMortgageDossierMainByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useDhsMortgageDossierMainById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLDhsMortgageDossierMainByIdQuery, Types.GQLDhsMortgageDossierMainByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.DhsMortgageDossierMainByIdDocument, options);
};
export type PageDhsMortgageDossierMainByIdComp = React.FC<{data?: Types.GQLDhsMortgageDossierMainByIdQuery, error?: Apollo.ApolloError}>;
export const ssrDhsMortgageDossierMainById = {
      getServerPage: getServerPageDhsMortgageDossierMainById,
      
      usePage: useDhsMortgageDossierMainById,
    }
export async function getServerPageGetDhsMortgageDossierStatusses
    (options: Omit<Apollo.QueryOptions<Types.GQLGetDhsMortgageDossierStatussesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetDhsMortgageDossierStatussesQuery>({ ...options, query: Operations.GetDhsMortgageDossierStatussesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetDhsMortgageDossierStatusses = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetDhsMortgageDossierStatussesQuery, Types.GQLGetDhsMortgageDossierStatussesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDhsMortgageDossierStatussesDocument, options);
};
export type PageGetDhsMortgageDossierStatussesComp = React.FC<{data?: Types.GQLGetDhsMortgageDossierStatussesQuery, error?: Apollo.ApolloError}>;
export const ssrGetDhsMortgageDossierStatusses = {
      getServerPage: getServerPageGetDhsMortgageDossierStatusses,
      
      usePage: useGetDhsMortgageDossierStatusses,
    }
export async function getServerPageDhsMortgageDossiersList
    (options: Omit<Apollo.QueryOptions<Types.GQLDhsMortgageDossiersListQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLDhsMortgageDossiersListQuery>({ ...options, query: Operations.DhsMortgageDossiersListDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useDhsMortgageDossiersList = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLDhsMortgageDossiersListQuery, Types.GQLDhsMortgageDossiersListQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.DhsMortgageDossiersListDocument, options);
};
export type PageDhsMortgageDossiersListComp = React.FC<{data?: Types.GQLDhsMortgageDossiersListQuery, error?: Apollo.ApolloError}>;
export const ssrDhsMortgageDossiersList = {
      getServerPage: getServerPageDhsMortgageDossiersList,
      
      usePage: useDhsMortgageDossiersList,
    }
export async function getServerPageGetDhsDocuments
    (options: Omit<Apollo.QueryOptions<Types.GQLGetDhsDocumentsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetDhsDocumentsQuery>({ ...options, query: Operations.GetDhsDocumentsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetDhsDocuments = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetDhsDocumentsQuery, Types.GQLGetDhsDocumentsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDhsDocumentsDocument, options);
};
export type PageGetDhsDocumentsComp = React.FC<{data?: Types.GQLGetDhsDocumentsQuery, error?: Apollo.ApolloError}>;
export const ssrGetDhsDocuments = {
      getServerPage: getServerPageGetDhsDocuments,
      
      usePage: useGetDhsDocuments,
    }
export async function getServerPageGetDhsDossierDocumentsByDossierId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetDhsDossierDocumentsByDossierIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetDhsDossierDocumentsByDossierIdQuery>({ ...options, query: Operations.GetDhsDossierDocumentsByDossierIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetDhsDossierDocumentsByDossierId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetDhsDossierDocumentsByDossierIdQuery, Types.GQLGetDhsDossierDocumentsByDossierIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDhsDossierDocumentsByDossierIdDocument, options);
};
export type PageGetDhsDossierDocumentsByDossierIdComp = React.FC<{data?: Types.GQLGetDhsDossierDocumentsByDossierIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetDhsDossierDocumentsByDossierId = {
      getServerPage: getServerPageGetDhsDossierDocumentsByDossierId,
      
      usePage: useGetDhsDossierDocumentsByDossierId,
    }
export async function getServerPageGetDhsFinancePlanById
    (options: Omit<Apollo.QueryOptions<Types.GQLGetDhsFinancePlanByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetDhsFinancePlanByIdQuery>({ ...options, query: Operations.GetDhsFinancePlanByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetDhsFinancePlanById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetDhsFinancePlanByIdQuery, Types.GQLGetDhsFinancePlanByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDhsFinancePlanByIdDocument, options);
};
export type PageGetDhsFinancePlanByIdComp = React.FC<{data?: Types.GQLGetDhsFinancePlanByIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetDhsFinancePlanById = {
      getServerPage: getServerPageGetDhsFinancePlanById,
      
      usePage: useGetDhsFinancePlanById,
    }
export async function getServerPageGetDhsMortgageDossierById
    (options: Omit<Apollo.QueryOptions<Types.GQLGetDhsMortgageDossierByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetDhsMortgageDossierByIdQuery>({ ...options, query: Operations.GetDhsMortgageDossierByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetDhsMortgageDossierById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetDhsMortgageDossierByIdQuery, Types.GQLGetDhsMortgageDossierByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDhsMortgageDossierByIdDocument, options);
};
export type PageGetDhsMortgageDossierByIdComp = React.FC<{data?: Types.GQLGetDhsMortgageDossierByIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetDhsMortgageDossierById = {
      getServerPage: getServerPageGetDhsMortgageDossierById,
      
      usePage: useGetDhsMortgageDossierById,
    }
export async function getServerPageGetDhsMortgageDossiers
    (options: Omit<Apollo.QueryOptions<Types.GQLGetDhsMortgageDossiersQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetDhsMortgageDossiersQuery>({ ...options, query: Operations.GetDhsMortgageDossiersDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetDhsMortgageDossiers = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetDhsMortgageDossiersQuery, Types.GQLGetDhsMortgageDossiersQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDhsMortgageDossiersDocument, options);
};
export type PageGetDhsMortgageDossiersComp = React.FC<{data?: Types.GQLGetDhsMortgageDossiersQuery, error?: Apollo.ApolloError}>;
export const ssrGetDhsMortgageDossiers = {
      getServerPage: getServerPageGetDhsMortgageDossiers,
      
      usePage: useGetDhsMortgageDossiers,
    }
















export async function getServerPageEbnNacecodeAccreditationMatrix
    (options: Omit<Apollo.QueryOptions<Types.GQLEbnNacecodeAccreditationMatrixQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLEbnNacecodeAccreditationMatrixQuery>({ ...options, query: Operations.EbnNacecodeAccreditationMatrixDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useEbnNacecodeAccreditationMatrix = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLEbnNacecodeAccreditationMatrixQuery, Types.GQLEbnNacecodeAccreditationMatrixQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.EbnNacecodeAccreditationMatrixDocument, options);
};
export type PageEbnNacecodeAccreditationMatrixComp = React.FC<{data?: Types.GQLEbnNacecodeAccreditationMatrixQuery, error?: Apollo.ApolloError}>;
export const ssrEbnNacecodeAccreditationMatrix = {
      getServerPage: getServerPageEbnNacecodeAccreditationMatrix,
      
      usePage: useEbnNacecodeAccreditationMatrix,
    }
export async function getServerPageEbnQuotationDocument
    (options: Omit<Apollo.QueryOptions<Types.GQLEbnQuotationDocumentQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLEbnQuotationDocumentQuery>({ ...options, query: Operations.EbnQuotationDocumentDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useEbnQuotationDocument = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLEbnQuotationDocumentQuery, Types.GQLEbnQuotationDocumentQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.EbnQuotationDocumentDocument, options);
};
export type PageEbnQuotationDocumentComp = React.FC<{data?: Types.GQLEbnQuotationDocumentQuery, error?: Apollo.ApolloError}>;
export const ssrEbnQuotationDocument = {
      getServerPage: getServerPageEbnQuotationDocument,
      
      usePage: useEbnQuotationDocument,
    }
export async function getServerPageGetAddressessByOrganizationIDs
    (options: Omit<Apollo.QueryOptions<Types.GQLGetAddressessByOrganizationIDsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetAddressessByOrganizationIDsQuery>({ ...options, query: Operations.GetAddressessByOrganizationIDsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetAddressessByOrganizationIDs = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetAddressessByOrganizationIDsQuery, Types.GQLGetAddressessByOrganizationIDsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAddressessByOrganizationIDsDocument, options);
};
export type PageGetAddressessByOrganizationIDsComp = React.FC<{data?: Types.GQLGetAddressessByOrganizationIDsQuery, error?: Apollo.ApolloError}>;
export const ssrGetAddressessByOrganizationIDs = {
      getServerPage: getServerPageGetAddressessByOrganizationIDs,
      
      usePage: useGetAddressessByOrganizationIDs,
    }
export async function getServerPageGetEbnCertNormDetailsByCertNormDetailsId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnCertNormDetailsByCertNormDetailsIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnCertNormDetailsByCertNormDetailsIdQuery>({ ...options, query: Operations.GetEbnCertNormDetailsByCertNormDetailsIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnCertNormDetailsByCertNormDetailsId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnCertNormDetailsByCertNormDetailsIdQuery, Types.GQLGetEbnCertNormDetailsByCertNormDetailsIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnCertNormDetailsByCertNormDetailsIdDocument, options);
};
export type PageGetEbnCertNormDetailsByCertNormDetailsIdComp = React.FC<{data?: Types.GQLGetEbnCertNormDetailsByCertNormDetailsIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnCertNormDetailsByCertNormDetailsId = {
      getServerPage: getServerPageGetEbnCertNormDetailsByCertNormDetailsId,
      
      usePage: useGetEbnCertNormDetailsByCertNormDetailsId,
    }
export async function getServerPageGetEbnCertNormDetailsByCertProcessId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnCertNormDetailsByCertProcessIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnCertNormDetailsByCertProcessIdQuery>({ ...options, query: Operations.GetEbnCertNormDetailsByCertProcessIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnCertNormDetailsByCertProcessId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnCertNormDetailsByCertProcessIdQuery, Types.GQLGetEbnCertNormDetailsByCertProcessIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnCertNormDetailsByCertProcessIdDocument, options);
};
export type PageGetEbnCertNormDetailsByCertProcessIdComp = React.FC<{data?: Types.GQLGetEbnCertNormDetailsByCertProcessIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnCertNormDetailsByCertProcessId = {
      getServerPage: getServerPageGetEbnCertNormDetailsByCertProcessId,
      
      usePage: useGetEbnCertNormDetailsByCertProcessId,
    }
export async function getServerPageGetEbnCertNormDetailsResult
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnCertNormDetailsResultQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnCertNormDetailsResultQuery>({ ...options, query: Operations.GetEbnCertNormDetailsResultDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnCertNormDetailsResult = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnCertNormDetailsResultQuery, Types.GQLGetEbnCertNormDetailsResultQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnCertNormDetailsResultDocument, options);
};
export type PageGetEbnCertNormDetailsResultComp = React.FC<{data?: Types.GQLGetEbnCertNormDetailsResultQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnCertNormDetailsResult = {
      getServerPage: getServerPageGetEbnCertNormDetailsResult,
      
      usePage: useGetEbnCertNormDetailsResult,
    }
export async function getServerPageGetEbnCertNormDetailsStatuses
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnCertNormDetailsStatusesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnCertNormDetailsStatusesQuery>({ ...options, query: Operations.GetEbnCertNormDetailsStatusesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnCertNormDetailsStatuses = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnCertNormDetailsStatusesQuery, Types.GQLGetEbnCertNormDetailsStatusesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnCertNormDetailsStatusesDocument, options);
};
export type PageGetEbnCertNormDetailsStatusesComp = React.FC<{data?: Types.GQLGetEbnCertNormDetailsStatusesQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnCertNormDetailsStatuses = {
      getServerPage: getServerPageGetEbnCertNormDetailsStatuses,
      
      usePage: useGetEbnCertNormDetailsStatuses,
    }
export async function getServerPageGetEbnCertProcess
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnCertProcessQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnCertProcessQuery>({ ...options, query: Operations.GetEbnCertProcessDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnCertProcess = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnCertProcessQuery, Types.GQLGetEbnCertProcessQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnCertProcessDocument, options);
};
export type PageGetEbnCertProcessComp = React.FC<{data?: Types.GQLGetEbnCertProcessQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnCertProcess = {
      getServerPage: getServerPageGetEbnCertProcess,
      
      usePage: useGetEbnCertProcess,
    }
export async function getServerPageGetEbnDocTemplates
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnDocTemplatesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnDocTemplatesQuery>({ ...options, query: Operations.GetEbnDocTemplatesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnDocTemplates = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnDocTemplatesQuery, Types.GQLGetEbnDocTemplatesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnDocTemplatesDocument, options);
};
export type PageGetEbnDocTemplatesComp = React.FC<{data?: Types.GQLGetEbnDocTemplatesQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnDocTemplates = {
      getServerPage: getServerPageGetEbnDocTemplates,
      
      usePage: useGetEbnDocTemplates,
    }
export async function getServerPageGetEbnNaceCodeList
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnNaceCodeListQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnNaceCodeListQuery>({ ...options, query: Operations.GetEbnNaceCodeListDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnNaceCodeList = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnNaceCodeListQuery, Types.GQLGetEbnNaceCodeListQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnNaceCodeListDocument, options);
};
export type PageGetEbnNaceCodeListComp = React.FC<{data?: Types.GQLGetEbnNaceCodeListQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnNaceCodeList = {
      getServerPage: getServerPageGetEbnNaceCodeList,
      
      usePage: useGetEbnNaceCodeList,
    }
export async function getServerPageGetEbnNormDetailPlanningTimeByCertProcessId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnNormDetailPlanningTimeByCertProcessIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnNormDetailPlanningTimeByCertProcessIdQuery>({ ...options, query: Operations.GetEbnNormDetailPlanningTimeByCertProcessIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnNormDetailPlanningTimeByCertProcessId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnNormDetailPlanningTimeByCertProcessIdQuery, Types.GQLGetEbnNormDetailPlanningTimeByCertProcessIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnNormDetailPlanningTimeByCertProcessIdDocument, options);
};
export type PageGetEbnNormDetailPlanningTimeByCertProcessIdComp = React.FC<{data?: Types.GQLGetEbnNormDetailPlanningTimeByCertProcessIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnNormDetailPlanningTimeByCertProcessId = {
      getServerPage: getServerPageGetEbnNormDetailPlanningTimeByCertProcessId,
      
      usePage: useGetEbnNormDetailPlanningTimeByCertProcessId,
    }
export async function getServerPageGetEbnNormDetailsByOrganizationId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnNormDetailsByOrganizationIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnNormDetailsByOrganizationIdQuery>({ ...options, query: Operations.GetEbnNormDetailsByOrganizationIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnNormDetailsByOrganizationId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnNormDetailsByOrganizationIdQuery, Types.GQLGetEbnNormDetailsByOrganizationIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnNormDetailsByOrganizationIdDocument, options);
};
export type PageGetEbnNormDetailsByOrganizationIdComp = React.FC<{data?: Types.GQLGetEbnNormDetailsByOrganizationIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnNormDetailsByOrganizationId = {
      getServerPage: getServerPageGetEbnNormDetailsByOrganizationId,
      
      usePage: useGetEbnNormDetailsByOrganizationId,
    }
export async function getServerPageGetEbnNormList
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnNormListQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnNormListQuery>({ ...options, query: Operations.GetEbnNormListDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnNormList = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnNormListQuery, Types.GQLGetEbnNormListQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnNormListDocument, options);
};
export type PageGetEbnNormListComp = React.FC<{data?: Types.GQLGetEbnNormListQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnNormList = {
      getServerPage: getServerPageGetEbnNormList,
      
      usePage: useGetEbnNormList,
    }
export async function getServerPageGetEbnNormOrganizationHierarchy
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnNormOrganizationHierarchyQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnNormOrganizationHierarchyQuery>({ ...options, query: Operations.GetEbnNormOrganizationHierarchyDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnNormOrganizationHierarchy = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnNormOrganizationHierarchyQuery, Types.GQLGetEbnNormOrganizationHierarchyQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnNormOrganizationHierarchyDocument, options);
};
export type PageGetEbnNormOrganizationHierarchyComp = React.FC<{data?: Types.GQLGetEbnNormOrganizationHierarchyQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnNormOrganizationHierarchy = {
      getServerPage: getServerPageGetEbnNormOrganizationHierarchy,
      
      usePage: useGetEbnNormOrganizationHierarchy,
    }
export async function getServerPageGetEbnPhasesByNorm
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnPhasesByNormQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnPhasesByNormQuery>({ ...options, query: Operations.GetEbnPhasesByNormDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnPhasesByNorm = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnPhasesByNormQuery, Types.GQLGetEbnPhasesByNormQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnPhasesByNormDocument, options);
};
export type PageGetEbnPhasesByNormComp = React.FC<{data?: Types.GQLGetEbnPhasesByNormQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnPhasesByNorm = {
      getServerPage: getServerPageGetEbnPhasesByNorm,
      
      usePage: useGetEbnPhasesByNorm,
    }
export async function getServerPageGetEbnOrganizationMainAddress
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnOrganizationMainAddressQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnOrganizationMainAddressQuery>({ ...options, query: Operations.GetEbnOrganizationMainAddressDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnOrganizationMainAddress = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnOrganizationMainAddressQuery, Types.GQLGetEbnOrganizationMainAddressQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnOrganizationMainAddressDocument, options);
};
export type PageGetEbnOrganizationMainAddressComp = React.FC<{data?: Types.GQLGetEbnOrganizationMainAddressQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnOrganizationMainAddress = {
      getServerPage: getServerPageGetEbnOrganizationMainAddress,
      
      usePage: useGetEbnOrganizationMainAddress,
    }
export async function getServerPageGetEbnPlanningRequestById
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnPlanningRequestByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnPlanningRequestByIdQuery>({ ...options, query: Operations.GetEbnPlanningRequestByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnPlanningRequestById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnPlanningRequestByIdQuery, Types.GQLGetEbnPlanningRequestByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnPlanningRequestByIdDocument, options);
};
export type PageGetEbnPlanningRequestByIdComp = React.FC<{data?: Types.GQLGetEbnPlanningRequestByIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnPlanningRequestById = {
      getServerPage: getServerPageGetEbnPlanningRequestById,
      
      usePage: useGetEbnPlanningRequestById,
    }
export async function getServerPageGetEbnPlanningRequests
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnPlanningRequestsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnPlanningRequestsQuery>({ ...options, query: Operations.GetEbnPlanningRequestsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnPlanningRequests = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnPlanningRequestsQuery, Types.GQLGetEbnPlanningRequestsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnPlanningRequestsDocument, options);
};
export type PageGetEbnPlanningRequestsComp = React.FC<{data?: Types.GQLGetEbnPlanningRequestsQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnPlanningRequests = {
      getServerPage: getServerPageGetEbnPlanningRequests,
      
      usePage: useGetEbnPlanningRequests,
    }
export async function getServerPageGetEbnPlanningRequestsByOrganizationId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnPlanningRequestsByOrganizationIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnPlanningRequestsByOrganizationIdQuery>({ ...options, query: Operations.GetEbnPlanningRequestsByOrganizationIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnPlanningRequestsByOrganizationId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnPlanningRequestsByOrganizationIdQuery, Types.GQLGetEbnPlanningRequestsByOrganizationIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnPlanningRequestsByOrganizationIdDocument, options);
};
export type PageGetEbnPlanningRequestsByOrganizationIdComp = React.FC<{data?: Types.GQLGetEbnPlanningRequestsByOrganizationIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnPlanningRequestsByOrganizationId = {
      getServerPage: getServerPageGetEbnPlanningRequestsByOrganizationId,
      
      usePage: useGetEbnPlanningRequestsByOrganizationId,
    }
export async function getServerPageGetEbnResourcesByCertProcessId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnResourcesByCertProcessIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnResourcesByCertProcessIdQuery>({ ...options, query: Operations.GetEbnResourcesByCertProcessIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnResourcesByCertProcessId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnResourcesByCertProcessIdQuery, Types.GQLGetEbnResourcesByCertProcessIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnResourcesByCertProcessIdDocument, options);
};
export type PageGetEbnResourcesByCertProcessIdComp = React.FC<{data?: Types.GQLGetEbnResourcesByCertProcessIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnResourcesByCertProcessId = {
      getServerPage: getServerPageGetEbnResourcesByCertProcessId,
      
      usePage: useGetEbnResourcesByCertProcessId,
    }
export async function getServerPageGetEaCodeByNaceCode
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEaCodeByNaceCodeQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEaCodeByNaceCodeQuery>({ ...options, query: Operations.GetEaCodeByNaceCodeDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEaCodeByNaceCode = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEaCodeByNaceCodeQuery, Types.GQLGetEaCodeByNaceCodeQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEaCodeByNaceCodeDocument, options);
};
export type PageGetEaCodeByNaceCodeComp = React.FC<{data?: Types.GQLGetEaCodeByNaceCodeQuery, error?: Apollo.ApolloError}>;
export const ssrGetEaCodeByNaceCode = {
      getServerPage: getServerPageGetEaCodeByNaceCode,
      
      usePage: useGetEaCodeByNaceCode,
    }
export async function getServerPageGetEbnPortalSearch
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEbnPortalSearchQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEbnPortalSearchQuery>({ ...options, query: Operations.GetEbnPortalSearchDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEbnPortalSearch = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEbnPortalSearchQuery, Types.GQLGetEbnPortalSearchQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEbnPortalSearchDocument, options);
};
export type PageGetEbnPortalSearchComp = React.FC<{data?: Types.GQLGetEbnPortalSearchQuery, error?: Apollo.ApolloError}>;
export const ssrGetEbnPortalSearch = {
      getServerPage: getServerPageGetEbnPortalSearch,
      
      usePage: useGetEbnPortalSearch,
    }
export async function getServerPageGetEmployeesByIDs
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEmployeesByIDsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEmployeesByIDsQuery>({ ...options, query: Operations.GetEmployeesByIDsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEmployeesByIDs = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEmployeesByIDsQuery, Types.GQLGetEmployeesByIDsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEmployeesByIDsDocument, options);
};
export type PageGetEmployeesByIDsComp = React.FC<{data?: Types.GQLGetEmployeesByIDsQuery, error?: Apollo.ApolloError}>;
export const ssrGetEmployeesByIDs = {
      getServerPage: getServerPageGetEmployeesByIDs,
      
      usePage: useGetEmployeesByIDs,
    }
export async function getServerPageGetGeoCoordsByAddressId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetGeoCoordsByAddressIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetGeoCoordsByAddressIdQuery>({ ...options, query: Operations.GetGeoCoordsByAddressIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetGeoCoordsByAddressId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetGeoCoordsByAddressIdQuery, Types.GQLGetGeoCoordsByAddressIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetGeoCoordsByAddressIdDocument, options);
};
export type PageGetGeoCoordsByAddressIdComp = React.FC<{data?: Types.GQLGetGeoCoordsByAddressIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetGeoCoordsByAddressId = {
      getServerPage: getServerPageGetGeoCoordsByAddressId,
      
      usePage: useGetGeoCoordsByAddressId,
    }
export async function getServerPageGetPlannedResourcesWithPropertiesFilter
    (options: Omit<Apollo.QueryOptions<Types.GQLGetPlannedResourcesWithPropertiesFilterQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetPlannedResourcesWithPropertiesFilterQuery>({ ...options, query: Operations.GetPlannedResourcesWithPropertiesFilterDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetPlannedResourcesWithPropertiesFilter = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetPlannedResourcesWithPropertiesFilterQuery, Types.GQLGetPlannedResourcesWithPropertiesFilterQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetPlannedResourcesWithPropertiesFilterDocument, options);
};
export type PageGetPlannedResourcesWithPropertiesFilterComp = React.FC<{data?: Types.GQLGetPlannedResourcesWithPropertiesFilterQuery, error?: Apollo.ApolloError}>;
export const ssrGetPlannedResourcesWithPropertiesFilter = {
      getServerPage: getServerPageGetPlannedResourcesWithPropertiesFilter,
      
      usePage: useGetPlannedResourcesWithPropertiesFilter,
    }








export async function getServerPageEbnAvailableReviewStatusTransitions
    (options: Omit<Apollo.QueryOptions<Types.GQLEbnAvailableReviewStatusTransitionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLEbnAvailableReviewStatusTransitionsQuery>({ ...options, query: Operations.EbnAvailableReviewStatusTransitionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useEbnAvailableReviewStatusTransitions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLEbnAvailableReviewStatusTransitionsQuery, Types.GQLEbnAvailableReviewStatusTransitionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.EbnAvailableReviewStatusTransitionsDocument, options);
};
export type PageEbnAvailableReviewStatusTransitionsComp = React.FC<{data?: Types.GQLEbnAvailableReviewStatusTransitionsQuery, error?: Apollo.ApolloError}>;
export const ssrEbnAvailableReviewStatusTransitions = {
      getServerPage: getServerPageEbnAvailableReviewStatusTransitions,
      
      usePage: useEbnAvailableReviewStatusTransitions,
    }
export async function getServerPageEbnReviewProcess
    (options: Omit<Apollo.QueryOptions<Types.GQLEbnReviewProcessQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLEbnReviewProcessQuery>({ ...options, query: Operations.EbnReviewProcessDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useEbnReviewProcess = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLEbnReviewProcessQuery, Types.GQLEbnReviewProcessQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.EbnReviewProcessDocument, options);
};
export type PageEbnReviewProcessComp = React.FC<{data?: Types.GQLEbnReviewProcessQuery, error?: Apollo.ApolloError}>;
export const ssrEbnReviewProcess = {
      getServerPage: getServerPageEbnReviewProcess,
      
      usePage: useEbnReviewProcess,
    }
export async function getServerPageEbnReviewProcessDocumentList
    (options: Omit<Apollo.QueryOptions<Types.GQLEbnReviewProcessDocumentListQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLEbnReviewProcessDocumentListQuery>({ ...options, query: Operations.EbnReviewProcessDocumentListDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useEbnReviewProcessDocumentList = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLEbnReviewProcessDocumentListQuery, Types.GQLEbnReviewProcessDocumentListQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.EbnReviewProcessDocumentListDocument, options);
};
export type PageEbnReviewProcessDocumentListComp = React.FC<{data?: Types.GQLEbnReviewProcessDocumentListQuery, error?: Apollo.ApolloError}>;
export const ssrEbnReviewProcessDocumentList = {
      getServerPage: getServerPageEbnReviewProcessDocumentList,
      
      usePage: useEbnReviewProcessDocumentList,
    }
export async function getServerPageEbnReviewProcessesOverview
    (options: Omit<Apollo.QueryOptions<Types.GQLEbnReviewProcessesOverviewQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLEbnReviewProcessesOverviewQuery>({ ...options, query: Operations.EbnReviewProcessesOverviewDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useEbnReviewProcessesOverview = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLEbnReviewProcessesOverviewQuery, Types.GQLEbnReviewProcessesOverviewQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.EbnReviewProcessesOverviewDocument, options);
};
export type PageEbnReviewProcessesOverviewComp = React.FC<{data?: Types.GQLEbnReviewProcessesOverviewQuery, error?: Apollo.ApolloError}>;
export const ssrEbnReviewProcessesOverview = {
      getServerPage: getServerPageEbnReviewProcessesOverview,
      
      usePage: useEbnReviewProcessesOverview,
    }





export async function getServerPageGetTaskOverviewByMetaData
    (options: Omit<Apollo.QueryOptions<Types.GQLGetTaskOverviewByMetaDataQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetTaskOverviewByMetaDataQuery>({ ...options, query: Operations.GetTaskOverviewByMetaDataDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetTaskOverviewByMetaData = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetTaskOverviewByMetaDataQuery, Types.GQLGetTaskOverviewByMetaDataQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetTaskOverviewByMetaDataDocument, options);
};
export type PageGetTaskOverviewByMetaDataComp = React.FC<{data?: Types.GQLGetTaskOverviewByMetaDataQuery, error?: Apollo.ApolloError}>;
export const ssrGetTaskOverviewByMetaData = {
      getServerPage: getServerPageGetTaskOverviewByMetaData,
      
      usePage: useGetTaskOverviewByMetaData,
    }
export async function getServerPageGetTaskByTaskId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetTaskByTaskIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetTaskByTaskIdQuery>({ ...options, query: Operations.GetTaskByTaskIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetTaskByTaskId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetTaskByTaskIdQuery, Types.GQLGetTaskByTaskIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetTaskByTaskIdDocument, options);
};
export type PageGetTaskByTaskIdComp = React.FC<{data?: Types.GQLGetTaskByTaskIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetTaskByTaskId = {
      getServerPage: getServerPageGetTaskByTaskId,
      
      usePage: useGetTaskByTaskId,
    }
export async function getServerPageGetTaskCommentsByTaskId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetTaskCommentsByTaskIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetTaskCommentsByTaskIdQuery>({ ...options, query: Operations.GetTaskCommentsByTaskIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetTaskCommentsByTaskId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetTaskCommentsByTaskIdQuery, Types.GQLGetTaskCommentsByTaskIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetTaskCommentsByTaskIdDocument, options);
};
export type PageGetTaskCommentsByTaskIdComp = React.FC<{data?: Types.GQLGetTaskCommentsByTaskIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetTaskCommentsByTaskId = {
      getServerPage: getServerPageGetTaskCommentsByTaskId,
      
      usePage: useGetTaskCommentsByTaskId,
    }
export async function getServerPageGetTaskOverview
    (options: Omit<Apollo.QueryOptions<Types.GQLGetTaskOverviewQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetTaskOverviewQuery>({ ...options, query: Operations.GetTaskOverviewDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetTaskOverview = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetTaskOverviewQuery, Types.GQLGetTaskOverviewQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetTaskOverviewDocument, options);
};
export type PageGetTaskOverviewComp = React.FC<{data?: Types.GQLGetTaskOverviewQuery, error?: Apollo.ApolloError}>;
export const ssrGetTaskOverview = {
      getServerPage: getServerPageGetTaskOverview,
      
      usePage: useGetTaskOverview,
    }







export async function getServerPageGetSupportAgents
    (options: Omit<Apollo.QueryOptions<Types.GQLGetSupportAgentsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetSupportAgentsQuery>({ ...options, query: Operations.GetSupportAgentsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSupportAgents = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetSupportAgentsQuery, Types.GQLGetSupportAgentsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSupportAgentsDocument, options);
};
export type PageGetSupportAgentsComp = React.FC<{data?: Types.GQLGetSupportAgentsQuery, error?: Apollo.ApolloError}>;
export const ssrGetSupportAgents = {
      getServerPage: getServerPageGetSupportAgents,
      
      usePage: useGetSupportAgents,
    }
export async function getServerPageGetSupportAttachmentsByRequestId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetSupportAttachmentsByRequestIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetSupportAttachmentsByRequestIdQuery>({ ...options, query: Operations.GetSupportAttachmentsByRequestIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSupportAttachmentsByRequestId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetSupportAttachmentsByRequestIdQuery, Types.GQLGetSupportAttachmentsByRequestIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSupportAttachmentsByRequestIdDocument, options);
};
export type PageGetSupportAttachmentsByRequestIdComp = React.FC<{data?: Types.GQLGetSupportAttachmentsByRequestIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetSupportAttachmentsByRequestId = {
      getServerPage: getServerPageGetSupportAttachmentsByRequestId,
      
      usePage: useGetSupportAttachmentsByRequestId,
    }
export async function getServerPageGetSupportAvailbaleTransitions
    (options: Omit<Apollo.QueryOptions<Types.GQLGetSupportAvailbaleTransitionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetSupportAvailbaleTransitionsQuery>({ ...options, query: Operations.GetSupportAvailbaleTransitionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSupportAvailbaleTransitions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetSupportAvailbaleTransitionsQuery, Types.GQLGetSupportAvailbaleTransitionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSupportAvailbaleTransitionsDocument, options);
};
export type PageGetSupportAvailbaleTransitionsComp = React.FC<{data?: Types.GQLGetSupportAvailbaleTransitionsQuery, error?: Apollo.ApolloError}>;
export const ssrGetSupportAvailbaleTransitions = {
      getServerPage: getServerPageGetSupportAvailbaleTransitions,
      
      usePage: useGetSupportAvailbaleTransitions,
    }
export async function getServerPageGetSupportCategory
    (options: Omit<Apollo.QueryOptions<Types.GQLGetSupportCategoryQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetSupportCategoryQuery>({ ...options, query: Operations.GetSupportCategoryDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSupportCategory = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetSupportCategoryQuery, Types.GQLGetSupportCategoryQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSupportCategoryDocument, options);
};
export type PageGetSupportCategoryComp = React.FC<{data?: Types.GQLGetSupportCategoryQuery, error?: Apollo.ApolloError}>;
export const ssrGetSupportCategory = {
      getServerPage: getServerPageGetSupportCategory,
      
      usePage: useGetSupportCategory,
    }
export async function getServerPageGetSupportCommentsByRequestId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetSupportCommentsByRequestIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetSupportCommentsByRequestIdQuery>({ ...options, query: Operations.GetSupportCommentsByRequestIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSupportCommentsByRequestId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetSupportCommentsByRequestIdQuery, Types.GQLGetSupportCommentsByRequestIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSupportCommentsByRequestIdDocument, options);
};
export type PageGetSupportCommentsByRequestIdComp = React.FC<{data?: Types.GQLGetSupportCommentsByRequestIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetSupportCommentsByRequestId = {
      getServerPage: getServerPageGetSupportCommentsByRequestId,
      
      usePage: useGetSupportCommentsByRequestId,
    }
export async function getServerPageSupportIsAgent
    (options: Omit<Apollo.QueryOptions<Types.GQLSupportIsAgentQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLSupportIsAgentQuery>({ ...options, query: Operations.SupportIsAgentDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useSupportIsAgent = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLSupportIsAgentQuery, Types.GQLSupportIsAgentQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.SupportIsAgentDocument, options);
};
export type PageSupportIsAgentComp = React.FC<{data?: Types.GQLSupportIsAgentQuery, error?: Apollo.ApolloError}>;
export const ssrSupportIsAgent = {
      getServerPage: getServerPageSupportIsAgent,
      
      usePage: useSupportIsAgent,
    }
export async function getServerPageGetSupportJiraDetails
    (options: Omit<Apollo.QueryOptions<Types.GQLGetSupportJiraDetailsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetSupportJiraDetailsQuery>({ ...options, query: Operations.GetSupportJiraDetailsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSupportJiraDetails = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetSupportJiraDetailsQuery, Types.GQLGetSupportJiraDetailsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSupportJiraDetailsDocument, options);
};
export type PageGetSupportJiraDetailsComp = React.FC<{data?: Types.GQLGetSupportJiraDetailsQuery, error?: Apollo.ApolloError}>;
export const ssrGetSupportJiraDetails = {
      getServerPage: getServerPageGetSupportJiraDetails,
      
      usePage: useGetSupportJiraDetails,
    }
export async function getServerPageGetSupportPlanningOverview
    (options: Omit<Apollo.QueryOptions<Types.GQLGetSupportPlanningOverviewQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetSupportPlanningOverviewQuery>({ ...options, query: Operations.GetSupportPlanningOverviewDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSupportPlanningOverview = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetSupportPlanningOverviewQuery, Types.GQLGetSupportPlanningOverviewQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSupportPlanningOverviewDocument, options);
};
export type PageGetSupportPlanningOverviewComp = React.FC<{data?: Types.GQLGetSupportPlanningOverviewQuery, error?: Apollo.ApolloError}>;
export const ssrGetSupportPlanningOverview = {
      getServerPage: getServerPageGetSupportPlanningOverview,
      
      usePage: useGetSupportPlanningOverview,
    }
export async function getServerPageGetSupportRequestById
    (options: Omit<Apollo.QueryOptions<Types.GQLGetSupportRequestByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetSupportRequestByIdQuery>({ ...options, query: Operations.GetSupportRequestByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSupportRequestById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetSupportRequestByIdQuery, Types.GQLGetSupportRequestByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSupportRequestByIdDocument, options);
};
export type PageGetSupportRequestByIdComp = React.FC<{data?: Types.GQLGetSupportRequestByIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetSupportRequestById = {
      getServerPage: getServerPageGetSupportRequestById,
      
      usePage: useGetSupportRequestById,
    }
export async function getServerPageGetSupportRequestBySprintId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetSupportRequestBySprintIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetSupportRequestBySprintIdQuery>({ ...options, query: Operations.GetSupportRequestBySprintIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSupportRequestBySprintId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetSupportRequestBySprintIdQuery, Types.GQLGetSupportRequestBySprintIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSupportRequestBySprintIdDocument, options);
};
export type PageGetSupportRequestBySprintIdComp = React.FC<{data?: Types.GQLGetSupportRequestBySprintIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetSupportRequestBySprintId = {
      getServerPage: getServerPageGetSupportRequestBySprintId,
      
      usePage: useGetSupportRequestBySprintId,
    }
export async function getServerPageGetSupportRequestDetailsById
    (options: Omit<Apollo.QueryOptions<Types.GQLGetSupportRequestDetailsByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetSupportRequestDetailsByIdQuery>({ ...options, query: Operations.GetSupportRequestDetailsByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSupportRequestDetailsById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetSupportRequestDetailsByIdQuery, Types.GQLGetSupportRequestDetailsByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSupportRequestDetailsByIdDocument, options);
};
export type PageGetSupportRequestDetailsByIdComp = React.FC<{data?: Types.GQLGetSupportRequestDetailsByIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetSupportRequestDetailsById = {
      getServerPage: getServerPageGetSupportRequestDetailsById,
      
      usePage: useGetSupportRequestDetailsById,
    }
export async function getServerPageGetSupportRequestOverview
    (options: Omit<Apollo.QueryOptions<Types.GQLGetSupportRequestOverviewQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetSupportRequestOverviewQuery>({ ...options, query: Operations.GetSupportRequestOverviewDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSupportRequestOverview = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetSupportRequestOverviewQuery, Types.GQLGetSupportRequestOverviewQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSupportRequestOverviewDocument, options);
};
export type PageGetSupportRequestOverviewComp = React.FC<{data?: Types.GQLGetSupportRequestOverviewQuery, error?: Apollo.ApolloError}>;
export const ssrGetSupportRequestOverview = {
      getServerPage: getServerPageGetSupportRequestOverview,
      
      usePage: useGetSupportRequestOverview,
    }
export async function getServerPageGetSupportUsers
    (options: Omit<Apollo.QueryOptions<Types.GQLGetSupportUsersQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetSupportUsersQuery>({ ...options, query: Operations.GetSupportUsersDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSupportUsers = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetSupportUsersQuery, Types.GQLGetSupportUsersQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSupportUsersDocument, options);
};
export type PageGetSupportUsersComp = React.FC<{data?: Types.GQLGetSupportUsersQuery, error?: Apollo.ApolloError}>;
export const ssrGetSupportUsers = {
      getServerPage: getServerPageGetSupportUsers,
      
      usePage: useGetSupportUsers,
    }
export async function getServerPageAuditLogDataById
    (options: Omit<Apollo.QueryOptions<Types.GQLAuditLogDataByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLAuditLogDataByIdQuery>({ ...options, query: Operations.AuditLogDataByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useAuditLogDataById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLAuditLogDataByIdQuery, Types.GQLAuditLogDataByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.AuditLogDataByIdDocument, options);
};
export type PageAuditLogDataByIdComp = React.FC<{data?: Types.GQLAuditLogDataByIdQuery, error?: Apollo.ApolloError}>;
export const ssrAuditLogDataById = {
      getServerPage: getServerPageAuditLogDataById,
      
      usePage: useAuditLogDataById,
    }
export async function getServerPageGetAuditsByDomainEntityKey
    (options: Omit<Apollo.QueryOptions<Types.GQLGetAuditsByDomainEntityKeyQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetAuditsByDomainEntityKeyQuery>({ ...options, query: Operations.GetAuditsByDomainEntityKeyDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetAuditsByDomainEntityKey = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetAuditsByDomainEntityKeyQuery, Types.GQLGetAuditsByDomainEntityKeyQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAuditsByDomainEntityKeyDocument, options);
};
export type PageGetAuditsByDomainEntityKeyComp = React.FC<{data?: Types.GQLGetAuditsByDomainEntityKeyQuery, error?: Apollo.ApolloError}>;
export const ssrGetAuditsByDomainEntityKey = {
      getServerPage: getServerPageGetAuditsByDomainEntityKey,
      
      usePage: useGetAuditsByDomainEntityKey,
    }
export async function getServerPageListAllEventTypes
    (options: Omit<Apollo.QueryOptions<Types.GQLListAllEventTypesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLListAllEventTypesQuery>({ ...options, query: Operations.ListAllEventTypesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useListAllEventTypes = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLListAllEventTypesQuery, Types.GQLListAllEventTypesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ListAllEventTypesDocument, options);
};
export type PageListAllEventTypesComp = React.FC<{data?: Types.GQLListAllEventTypesQuery, error?: Apollo.ApolloError}>;
export const ssrListAllEventTypes = {
      getServerPage: getServerPageListAllEventTypes,
      
      usePage: useListAllEventTypes,
    }
export async function getServerPageContentDefaultFolderStructureByDomainAndEntity
    (options: Omit<Apollo.QueryOptions<Types.GQLContentDefaultFolderStructureByDomainAndEntityQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLContentDefaultFolderStructureByDomainAndEntityQuery>({ ...options, query: Operations.ContentDefaultFolderStructureByDomainAndEntityDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useContentDefaultFolderStructureByDomainAndEntity = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLContentDefaultFolderStructureByDomainAndEntityQuery, Types.GQLContentDefaultFolderStructureByDomainAndEntityQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ContentDefaultFolderStructureByDomainAndEntityDocument, options);
};
export type PageContentDefaultFolderStructureByDomainAndEntityComp = React.FC<{data?: Types.GQLContentDefaultFolderStructureByDomainAndEntityQuery, error?: Apollo.ApolloError}>;
export const ssrContentDefaultFolderStructureByDomainAndEntity = {
      getServerPage: getServerPageContentDefaultFolderStructureByDomainAndEntity,
      
      usePage: useContentDefaultFolderStructureByDomainAndEntity,
    }
export async function getServerPageContentDefaultFolderStructureOverview
    (options: Omit<Apollo.QueryOptions<Types.GQLContentDefaultFolderStructureOverviewQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLContentDefaultFolderStructureOverviewQuery>({ ...options, query: Operations.ContentDefaultFolderStructureOverviewDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useContentDefaultFolderStructureOverview = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLContentDefaultFolderStructureOverviewQuery, Types.GQLContentDefaultFolderStructureOverviewQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ContentDefaultFolderStructureOverviewDocument, options);
};
export type PageContentDefaultFolderStructureOverviewComp = React.FC<{data?: Types.GQLContentDefaultFolderStructureOverviewQuery, error?: Apollo.ApolloError}>;
export const ssrContentDefaultFolderStructureOverview = {
      getServerPage: getServerPageContentDefaultFolderStructureOverview,
      
      usePage: useContentDefaultFolderStructureOverview,
    }
export async function getServerPageContentItemById
    (options: Omit<Apollo.QueryOptions<Types.GQLContentItemByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLContentItemByIdQuery>({ ...options, query: Operations.ContentItemByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useContentItemById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLContentItemByIdQuery, Types.GQLContentItemByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ContentItemByIdDocument, options);
};
export type PageContentItemByIdComp = React.FC<{data?: Types.GQLContentItemByIdQuery, error?: Apollo.ApolloError}>;
export const ssrContentItemById = {
      getServerPage: getServerPageContentItemById,
      
      usePage: useContentItemById,
    }





export async function getServerPageGetContentDirList
    (options: Omit<Apollo.QueryOptions<Types.GQLGetContentDirListQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetContentDirListQuery>({ ...options, query: Operations.GetContentDirListDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetContentDirList = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetContentDirListQuery, Types.GQLGetContentDirListQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetContentDirListDocument, options);
};
export type PageGetContentDirListComp = React.FC<{data?: Types.GQLGetContentDirListQuery, error?: Apollo.ApolloError}>;
export const ssrGetContentDirList = {
      getServerPage: getServerPageGetContentDirList,
      
      usePage: useGetContentDirList,
    }
export async function getServerPageGetContentItemById
    (options: Omit<Apollo.QueryOptions<Types.GQLGetContentItemByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetContentItemByIdQuery>({ ...options, query: Operations.GetContentItemByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetContentItemById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetContentItemByIdQuery, Types.GQLGetContentItemByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetContentItemByIdDocument, options);
};
export type PageGetContentItemByIdComp = React.FC<{data?: Types.GQLGetContentItemByIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetContentItemById = {
      getServerPage: getServerPageGetContentItemById,
      
      usePage: useGetContentItemById,
    }
export async function getServerPageGetContentItemByMetaData
    (options: Omit<Apollo.QueryOptions<Types.GQLGetContentItemByMetaDataQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetContentItemByMetaDataQuery>({ ...options, query: Operations.GetContentItemByMetaDataDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetContentItemByMetaData = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetContentItemByMetaDataQuery, Types.GQLGetContentItemByMetaDataQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetContentItemByMetaDataDocument, options);
};
export type PageGetContentItemByMetaDataComp = React.FC<{data?: Types.GQLGetContentItemByMetaDataQuery, error?: Apollo.ApolloError}>;
export const ssrGetContentItemByMetaData = {
      getServerPage: getServerPageGetContentItemByMetaData,
      
      usePage: useGetContentItemByMetaData,
    }
export async function getServerPageGetContentRootIdByMetaData
    (options: Omit<Apollo.QueryOptions<Types.GQLGetContentRootIdByMetaDataQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetContentRootIdByMetaDataQuery>({ ...options, query: Operations.GetContentRootIdByMetaDataDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetContentRootIdByMetaData = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetContentRootIdByMetaDataQuery, Types.GQLGetContentRootIdByMetaDataQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetContentRootIdByMetaDataDocument, options);
};
export type PageGetContentRootIdByMetaDataComp = React.FC<{data?: Types.GQLGetContentRootIdByMetaDataQuery, error?: Apollo.ApolloError}>;
export const ssrGetContentRootIdByMetaData = {
      getServerPage: getServerPageGetContentRootIdByMetaData,
      
      usePage: useGetContentRootIdByMetaData,
    }

export async function getServerPageGetEmployees
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEmployeesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEmployeesQuery>({ ...options, query: Operations.GetEmployeesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEmployees = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEmployeesQuery, Types.GQLGetEmployeesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEmployeesDocument, options);
};
export type PageGetEmployeesComp = React.FC<{data?: Types.GQLGetEmployeesQuery, error?: Apollo.ApolloError}>;
export const ssrGetEmployees = {
      getServerPage: getServerPageGetEmployees,
      
      usePage: useGetEmployees,
    }
export async function getServerPageFormById
    (options: Omit<Apollo.QueryOptions<Types.GQLFormByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLFormByIdQuery>({ ...options, query: Operations.FormByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useFormById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLFormByIdQuery, Types.GQLFormByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.FormByIdDocument, options);
};
export type PageFormByIdComp = React.FC<{data?: Types.GQLFormByIdQuery, error?: Apollo.ApolloError}>;
export const ssrFormById = {
      getServerPage: getServerPageFormById,
      
      usePage: useFormById,
    }
export async function getServerPageGetLanguages
    (options: Omit<Apollo.QueryOptions<Types.GQLGetLanguagesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetLanguagesQuery>({ ...options, query: Operations.GetLanguagesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetLanguages = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetLanguagesQuery, Types.GQLGetLanguagesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetLanguagesDocument, options);
};
export type PageGetLanguagesComp = React.FC<{data?: Types.GQLGetLanguagesQuery, error?: Apollo.ApolloError}>;
export const ssrGetLanguages = {
      getServerPage: getServerPageGetLanguages,
      
      usePage: useGetLanguages,
    }

export async function getServerPageReleaseNotesById
    (options: Omit<Apollo.QueryOptions<Types.GQLReleaseNotesByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLReleaseNotesByIdQuery>({ ...options, query: Operations.ReleaseNotesByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useReleaseNotesById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLReleaseNotesByIdQuery, Types.GQLReleaseNotesByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ReleaseNotesByIdDocument, options);
};
export type PageReleaseNotesByIdComp = React.FC<{data?: Types.GQLReleaseNotesByIdQuery, error?: Apollo.ApolloError}>;
export const ssrReleaseNotesById = {
      getServerPage: getServerPageReleaseNotesById,
      
      usePage: useReleaseNotesById,
    }


export async function getServerPageGetAllNotifications
    (options: Omit<Apollo.QueryOptions<Types.GQLGetAllNotificationsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetAllNotificationsQuery>({ ...options, query: Operations.GetAllNotificationsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetAllNotifications = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetAllNotificationsQuery, Types.GQLGetAllNotificationsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAllNotificationsDocument, options);
};
export type PageGetAllNotificationsComp = React.FC<{data?: Types.GQLGetAllNotificationsQuery, error?: Apollo.ApolloError}>;
export const ssrGetAllNotifications = {
      getServerPage: getServerPageGetAllNotifications,
      
      usePage: useGetAllNotifications,
    }


export async function getServerPageGetActivityType
    (options: Omit<Apollo.QueryOptions<Types.GQLGetActivityTypeQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetActivityTypeQuery>({ ...options, query: Operations.GetActivityTypeDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetActivityType = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetActivityTypeQuery, Types.GQLGetActivityTypeQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetActivityTypeDocument, options);
};
export type PageGetActivityTypeComp = React.FC<{data?: Types.GQLGetActivityTypeQuery, error?: Apollo.ApolloError}>;
export const ssrGetActivityType = {
      getServerPage: getServerPageGetActivityType,
      
      usePage: useGetActivityType,
    }
export async function getServerPageGetActivityTypesByIDs
    (options: Omit<Apollo.QueryOptions<Types.GQLGetActivityTypesByIDsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetActivityTypesByIDsQuery>({ ...options, query: Operations.GetActivityTypesByIDsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetActivityTypesByIDs = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetActivityTypesByIDsQuery, Types.GQLGetActivityTypesByIDsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetActivityTypesByIDsDocument, options);
};
export type PageGetActivityTypesByIDsComp = React.FC<{data?: Types.GQLGetActivityTypesByIDsQuery, error?: Apollo.ApolloError}>;
export const ssrGetActivityTypesByIDs = {
      getServerPage: getServerPageGetActivityTypesByIDs,
      
      usePage: useGetActivityTypesByIDs,
    }
export async function getServerPageGetMomentDetailsByMomentId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetMomentDetailsByMomentIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetMomentDetailsByMomentIdQuery>({ ...options, query: Operations.GetMomentDetailsByMomentIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetMomentDetailsByMomentId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetMomentDetailsByMomentIdQuery, Types.GQLGetMomentDetailsByMomentIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetMomentDetailsByMomentIdDocument, options);
};
export type PageGetMomentDetailsByMomentIdComp = React.FC<{data?: Types.GQLGetMomentDetailsByMomentIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetMomentDetailsByMomentId = {
      getServerPage: getServerPageGetMomentDetailsByMomentId,
      
      usePage: useGetMomentDetailsByMomentId,
    }
export async function getServerPageGetResourceAlaisedList
    (options: Omit<Apollo.QueryOptions<Types.GQLGetResourceAlaisedListQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetResourceAlaisedListQuery>({ ...options, query: Operations.GetResourceAlaisedListDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetResourceAlaisedList = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetResourceAlaisedListQuery, Types.GQLGetResourceAlaisedListQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetResourceAlaisedListDocument, options);
};
export type PageGetResourceAlaisedListComp = React.FC<{data?: Types.GQLGetResourceAlaisedListQuery, error?: Apollo.ApolloError}>;
export const ssrGetResourceAlaisedList = {
      getServerPage: getServerPageGetResourceAlaisedList,
      
      usePage: useGetResourceAlaisedList,
    }
export async function getServerPageGetResourceList
    (options: Omit<Apollo.QueryOptions<Types.GQLGetResourceListQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetResourceListQuery>({ ...options, query: Operations.GetResourceListDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetResourceList = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetResourceListQuery, Types.GQLGetResourceListQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetResourceListDocument, options);
};
export type PageGetResourceListComp = React.FC<{data?: Types.GQLGetResourceListQuery, error?: Apollo.ApolloError}>;
export const ssrGetResourceList = {
      getServerPage: getServerPageGetResourceList,
      
      usePage: useGetResourceList,
    }
export async function getServerPageGetResources
    (options: Omit<Apollo.QueryOptions<Types.GQLGetResourcesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetResourcesQuery>({ ...options, query: Operations.GetResourcesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetResources = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetResourcesQuery, Types.GQLGetResourcesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetResourcesDocument, options);
};
export type PageGetResourcesComp = React.FC<{data?: Types.GQLGetResourcesQuery, error?: Apollo.ApolloError}>;
export const ssrGetResources = {
      getServerPage: getServerPageGetResources,
      
      usePage: useGetResources,
    }



export async function getServerPageCoCbyCocNumber
    (options: Omit<Apollo.QueryOptions<Types.GQLCoCbyCocNumberQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCoCbyCocNumberQuery>({ ...options, query: Operations.CoCbyCocNumberDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCoCbyCocNumber = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCoCbyCocNumberQuery, Types.GQLCoCbyCocNumberQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CoCbyCocNumberDocument, options);
};
export type PageCoCbyCocNumberComp = React.FC<{data?: Types.GQLCoCbyCocNumberQuery, error?: Apollo.ApolloError}>;
export const ssrCoCbyCocNumber = {
      getServerPage: getServerPageCoCbyCocNumber,
      
      usePage: useCoCbyCocNumber,
    }
export async function getServerPageCocByOrganizationName
    (options: Omit<Apollo.QueryOptions<Types.GQLCocByOrganizationNameQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCocByOrganizationNameQuery>({ ...options, query: Operations.CocByOrganizationNameDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCocByOrganizationName = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCocByOrganizationNameQuery, Types.GQLCocByOrganizationNameQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CocByOrganizationNameDocument, options);
};
export type PageCocByOrganizationNameComp = React.FC<{data?: Types.GQLCocByOrganizationNameQuery, error?: Apollo.ApolloError}>;
export const ssrCocByOrganizationName = {
      getServerPage: getServerPageCocByOrganizationName,
      
      usePage: useCocByOrganizationName,
    }
export async function getServerPageGetAddressesByOrganizationIds
    (options: Omit<Apollo.QueryOptions<Types.GQLGetAddressesByOrganizationIdsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetAddressesByOrganizationIdsQuery>({ ...options, query: Operations.GetAddressesByOrganizationIdsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetAddressesByOrganizationIds = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetAddressesByOrganizationIdsQuery, Types.GQLGetAddressesByOrganizationIdsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAddressesByOrganizationIdsDocument, options);
};
export type PageGetAddressesByOrganizationIdsComp = React.FC<{data?: Types.GQLGetAddressesByOrganizationIdsQuery, error?: Apollo.ApolloError}>;
export const ssrGetAddressesByOrganizationIds = {
      getServerPage: getServerPageGetAddressesByOrganizationIds,
      
      usePage: useGetAddressesByOrganizationIds,
    }
export async function getServerPageGetGoogleMapsAutoCompletePredictions
    (options: Omit<Apollo.QueryOptions<Types.GQLGetGoogleMapsAutoCompletePredictionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetGoogleMapsAutoCompletePredictionsQuery>({ ...options, query: Operations.GetGoogleMapsAutoCompletePredictionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetGoogleMapsAutoCompletePredictions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetGoogleMapsAutoCompletePredictionsQuery, Types.GQLGetGoogleMapsAutoCompletePredictionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetGoogleMapsAutoCompletePredictionsDocument, options);
};
export type PageGetGoogleMapsAutoCompletePredictionsComp = React.FC<{data?: Types.GQLGetGoogleMapsAutoCompletePredictionsQuery, error?: Apollo.ApolloError}>;
export const ssrGetGoogleMapsAutoCompletePredictions = {
      getServerPage: getServerPageGetGoogleMapsAutoCompletePredictions,
      
      usePage: useGetGoogleMapsAutoCompletePredictions,
    }
export async function getServerPageGetGoogleMapsPlaceDetails
    (options: Omit<Apollo.QueryOptions<Types.GQLGetGoogleMapsPlaceDetailsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetGoogleMapsPlaceDetailsQuery>({ ...options, query: Operations.GetGoogleMapsPlaceDetailsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetGoogleMapsPlaceDetails = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetGoogleMapsPlaceDetailsQuery, Types.GQLGetGoogleMapsPlaceDetailsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetGoogleMapsPlaceDetailsDocument, options);
};
export type PageGetGoogleMapsPlaceDetailsComp = React.FC<{data?: Types.GQLGetGoogleMapsPlaceDetailsQuery, error?: Apollo.ApolloError}>;
export const ssrGetGoogleMapsPlaceDetails = {
      getServerPage: getServerPageGetGoogleMapsPlaceDetails,
      
      usePage: useGetGoogleMapsPlaceDetails,
    }
export async function getServerPageGetOrganizationByActiveProject
    (options: Omit<Apollo.QueryOptions<Types.GQLGetOrganizationByActiveProjectQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetOrganizationByActiveProjectQuery>({ ...options, query: Operations.GetOrganizationByActiveProjectDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetOrganizationByActiveProject = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetOrganizationByActiveProjectQuery, Types.GQLGetOrganizationByActiveProjectQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOrganizationByActiveProjectDocument, options);
};
export type PageGetOrganizationByActiveProjectComp = React.FC<{data?: Types.GQLGetOrganizationByActiveProjectQuery, error?: Apollo.ApolloError}>;
export const ssrGetOrganizationByActiveProject = {
      getServerPage: getServerPageGetOrganizationByActiveProject,
      
      usePage: useGetOrganizationByActiveProject,
    }
export async function getServerPageGetOrganizationPersonById
    (options: Omit<Apollo.QueryOptions<Types.GQLGetOrganizationPersonByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetOrganizationPersonByIdQuery>({ ...options, query: Operations.GetOrganizationPersonByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetOrganizationPersonById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetOrganizationPersonByIdQuery, Types.GQLGetOrganizationPersonByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOrganizationPersonByIdDocument, options);
};
export type PageGetOrganizationPersonByIdComp = React.FC<{data?: Types.GQLGetOrganizationPersonByIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetOrganizationPersonById = {
      getServerPage: getServerPageGetOrganizationPersonById,
      
      usePage: useGetOrganizationPersonById,
    }
export async function getServerPageGetOrganizationTypes
    (options: Omit<Apollo.QueryOptions<Types.GQLGetOrganizationTypesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetOrganizationTypesQuery>({ ...options, query: Operations.GetOrganizationTypesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetOrganizationTypes = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetOrganizationTypesQuery, Types.GQLGetOrganizationTypesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOrganizationTypesDocument, options);
};
export type PageGetOrganizationTypesComp = React.FC<{data?: Types.GQLGetOrganizationTypesQuery, error?: Apollo.ApolloError}>;
export const ssrGetOrganizationTypes = {
      getServerPage: getServerPageGetOrganizationTypes,
      
      usePage: useGetOrganizationTypes,
    }
export async function getServerPageGetOrganizations
    (options: Omit<Apollo.QueryOptions<Types.GQLGetOrganizationsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetOrganizationsQuery>({ ...options, query: Operations.GetOrganizationsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetOrganizations = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetOrganizationsQuery, Types.GQLGetOrganizationsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOrganizationsDocument, options);
};
export type PageGetOrganizationsComp = React.FC<{data?: Types.GQLGetOrganizationsQuery, error?: Apollo.ApolloError}>;
export const ssrGetOrganizations = {
      getServerPage: getServerPageGetOrganizations,
      
      usePage: useGetOrganizations,
    }
export async function getServerPageOrganizationsbyOrganizationTypeById
    (options: Omit<Apollo.QueryOptions<Types.GQLOrganizationsbyOrganizationTypeByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLOrganizationsbyOrganizationTypeByIdQuery>({ ...options, query: Operations.OrganizationsbyOrganizationTypeByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useOrganizationsbyOrganizationTypeById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLOrganizationsbyOrganizationTypeByIdQuery, Types.GQLOrganizationsbyOrganizationTypeByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OrganizationsbyOrganizationTypeByIdDocument, options);
};
export type PageOrganizationsbyOrganizationTypeByIdComp = React.FC<{data?: Types.GQLOrganizationsbyOrganizationTypeByIdQuery, error?: Apollo.ApolloError}>;
export const ssrOrganizationsbyOrganizationTypeById = {
      getServerPage: getServerPageOrganizationsbyOrganizationTypeById,
      
      usePage: useOrganizationsbyOrganizationTypeById,
    }
export async function getServerPageGetUserRoles
    (options: Omit<Apollo.QueryOptions<Types.GQLGetUserRolesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetUserRolesQuery>({ ...options, query: Operations.GetUserRolesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetUserRoles = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetUserRolesQuery, Types.GQLGetUserRolesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetUserRolesDocument, options);
};
export type PageGetUserRolesComp = React.FC<{data?: Types.GQLGetUserRolesQuery, error?: Apollo.ApolloError}>;
export const ssrGetUserRoles = {
      getServerPage: getServerPageGetUserRoles,
      
      usePage: useGetUserRoles,
    }
export async function getServerPageGetRootOrganizationHierarchy
    (options: Omit<Apollo.QueryOptions<Types.GQLGetRootOrganizationHierarchyQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetRootOrganizationHierarchyQuery>({ ...options, query: Operations.GetRootOrganizationHierarchyDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetRootOrganizationHierarchy = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetRootOrganizationHierarchyQuery, Types.GQLGetRootOrganizationHierarchyQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetRootOrganizationHierarchyDocument, options);
};
export type PageGetRootOrganizationHierarchyComp = React.FC<{data?: Types.GQLGetRootOrganizationHierarchyQuery, error?: Apollo.ApolloError}>;
export const ssrGetRootOrganizationHierarchy = {
      getServerPage: getServerPageGetRootOrganizationHierarchy,
      
      usePage: useGetRootOrganizationHierarchy,
    }
export async function getServerPageTasksByMultipleMetadata
    (options: Omit<Apollo.QueryOptions<Types.GQLTasksByMultipleMetadataQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLTasksByMultipleMetadataQuery>({ ...options, query: Operations.TasksByMultipleMetadataDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useTasksByMultipleMetadata = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLTasksByMultipleMetadataQuery, Types.GQLTasksByMultipleMetadataQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.TasksByMultipleMetadataDocument, options);
};
export type PageTasksByMultipleMetadataComp = React.FC<{data?: Types.GQLTasksByMultipleMetadataQuery, error?: Apollo.ApolloError}>;
export const ssrTasksByMultipleMetadata = {
      getServerPage: getServerPageTasksByMultipleMetadata,
      
      usePage: useTasksByMultipleMetadata,
    }


export async function getServerPageUserAccounts
    (options: Omit<Apollo.QueryOptions<Types.GQLUserAccountsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLUserAccountsQuery>({ ...options, query: Operations.UserAccountsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useUserAccounts = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLUserAccountsQuery, Types.GQLUserAccountsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.UserAccountsDocument, options);
};
export type PageUserAccountsComp = React.FC<{data?: Types.GQLUserAccountsQuery, error?: Apollo.ApolloError}>;
export const ssrUserAccounts = {
      getServerPage: getServerPageUserAccounts,
      
      usePage: useUserAccounts,
    }










































































export async function getServerPageAddressById
    (options: Omit<Apollo.QueryOptions<Types.GQLAddressByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLAddressByIdQuery>({ ...options, query: Operations.AddressByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useAddressById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLAddressByIdQuery, Types.GQLAddressByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.AddressByIdDocument, options);
};
export type PageAddressByIdComp = React.FC<{data?: Types.GQLAddressByIdQuery, error?: Apollo.ApolloError}>;
export const ssrAddressById = {
      getServerPage: getServerPageAddressById,
      
      usePage: useAddressById,
    }
export async function getServerPageCanAccess
    (options: Omit<Apollo.QueryOptions<Types.GQLCanAccessQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCanAccessQuery>({ ...options, query: Operations.CanAccessDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCanAccess = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCanAccessQuery, Types.GQLCanAccessQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CanAccessDocument, options);
};
export type PageCanAccessComp = React.FC<{data?: Types.GQLCanAccessQuery, error?: Apollo.ApolloError}>;
export const ssrCanAccess = {
      getServerPage: getServerPageCanAccess,
      
      usePage: useCanAccess,
    }
export async function getServerPageFlatMenus
    (options: Omit<Apollo.QueryOptions<Types.GQLFlatMenusQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLFlatMenusQuery>({ ...options, query: Operations.FlatMenusDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useFlatMenus = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLFlatMenusQuery, Types.GQLFlatMenusQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.FlatMenusDocument, options);
};
export type PageFlatMenusComp = React.FC<{data?: Types.GQLFlatMenusQuery, error?: Apollo.ApolloError}>;
export const ssrFlatMenus = {
      getServerPage: getServerPageFlatMenus,
      
      usePage: useFlatMenus,
    }
export async function getServerPageGetChildOrganisations
    (options: Omit<Apollo.QueryOptions<Types.GQLGetChildOrganisationsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetChildOrganisationsQuery>({ ...options, query: Operations.GetChildOrganisationsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetChildOrganisations = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetChildOrganisationsQuery, Types.GQLGetChildOrganisationsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetChildOrganisationsDocument, options);
};
export type PageGetChildOrganisationsComp = React.FC<{data?: Types.GQLGetChildOrganisationsQuery, error?: Apollo.ApolloError}>;
export const ssrGetChildOrganisations = {
      getServerPage: getServerPageGetChildOrganisations,
      
      usePage: useGetChildOrganisations,
    }
export async function getServerPageCurrentLoggedinUser
    (options: Omit<Apollo.QueryOptions<Types.GQLCurrentLoggedinUserQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCurrentLoggedinUserQuery>({ ...options, query: Operations.CurrentLoggedinUserDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCurrentLoggedinUser = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCurrentLoggedinUserQuery, Types.GQLCurrentLoggedinUserQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CurrentLoggedinUserDocument, options);
};
export type PageCurrentLoggedinUserComp = React.FC<{data?: Types.GQLCurrentLoggedinUserQuery, error?: Apollo.ApolloError}>;
export const ssrCurrentLoggedinUser = {
      getServerPage: getServerPageCurrentLoggedinUser,
      
      usePage: useCurrentLoggedinUser,
    }
export async function getServerPageGetForms
    (options: Omit<Apollo.QueryOptions<Types.GQLGetFormsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetFormsQuery>({ ...options, query: Operations.GetFormsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetForms = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetFormsQuery, Types.GQLGetFormsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetFormsDocument, options);
};
export type PageGetFormsComp = React.FC<{data?: Types.GQLGetFormsQuery, error?: Apollo.ApolloError}>;
export const ssrGetForms = {
      getServerPage: getServerPageGetForms,
      
      usePage: useGetForms,
    }
export async function getServerPageGetOrganizationHierarchyPath
    (options: Omit<Apollo.QueryOptions<Types.GQLGetOrganizationHierarchyPathQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetOrganizationHierarchyPathQuery>({ ...options, query: Operations.GetOrganizationHierarchyPathDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetOrganizationHierarchyPath = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetOrganizationHierarchyPathQuery, Types.GQLGetOrganizationHierarchyPathQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOrganizationHierarchyPathDocument, options);
};
export type PageGetOrganizationHierarchyPathComp = React.FC<{data?: Types.GQLGetOrganizationHierarchyPathQuery, error?: Apollo.ApolloError}>;
export const ssrGetOrganizationHierarchyPath = {
      getServerPage: getServerPageGetOrganizationHierarchyPath,
      
      usePage: useGetOrganizationHierarchyPath,
    }
export async function getServerPageIsPermitted
    (options: Omit<Apollo.QueryOptions<Types.GQLIsPermittedQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLIsPermittedQuery>({ ...options, query: Operations.IsPermittedDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useIsPermitted = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLIsPermittedQuery, Types.GQLIsPermittedQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.IsPermittedDocument, options);
};
export type PageIsPermittedComp = React.FC<{data?: Types.GQLIsPermittedQuery, error?: Apollo.ApolloError}>;
export const ssrIsPermitted = {
      getServerPage: getServerPageIsPermitted,
      
      usePage: useIsPermitted,
    }
export async function getServerPageGetMenuItems
    (options: Omit<Apollo.QueryOptions<Types.GQLGetMenuItemsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetMenuItemsQuery>({ ...options, query: Operations.GetMenuItemsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetMenuItems = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetMenuItemsQuery, Types.GQLGetMenuItemsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetMenuItemsDocument, options);
};
export type PageGetMenuItemsComp = React.FC<{data?: Types.GQLGetMenuItemsQuery, error?: Apollo.ApolloError}>;
export const ssrGetMenuItems = {
      getServerPage: getServerPageGetMenuItems,
      
      usePage: useGetMenuItems,
    }
export async function getServerPageGetOrganizationById
    (options: Omit<Apollo.QueryOptions<Types.GQLGetOrganizationByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetOrganizationByIdQuery>({ ...options, query: Operations.GetOrganizationByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetOrganizationById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetOrganizationByIdQuery, Types.GQLGetOrganizationByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOrganizationByIdDocument, options);
};
export type PageGetOrganizationByIdComp = React.FC<{data?: Types.GQLGetOrganizationByIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetOrganizationById = {
      getServerPage: getServerPageGetOrganizationById,
      
      usePage: useGetOrganizationById,
    }
export async function getServerPageGetAllOrganizationsPersons
    (options: Omit<Apollo.QueryOptions<Types.GQLGetAllOrganizationsPersonsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetAllOrganizationsPersonsQuery>({ ...options, query: Operations.GetAllOrganizationsPersonsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetAllOrganizationsPersons = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetAllOrganizationsPersonsQuery, Types.GQLGetAllOrganizationsPersonsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAllOrganizationsPersonsDocument, options);
};
export type PageGetAllOrganizationsPersonsComp = React.FC<{data?: Types.GQLGetAllOrganizationsPersonsQuery, error?: Apollo.ApolloError}>;
export const ssrGetAllOrganizationsPersons = {
      getServerPage: getServerPageGetAllOrganizationsPersons,
      
      usePage: useGetAllOrganizationsPersons,
    }
export async function getServerPageOrganizationsAndVisitAddress
    (options: Omit<Apollo.QueryOptions<Types.GQLOrganizationsAndVisitAddressQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLOrganizationsAndVisitAddressQuery>({ ...options, query: Operations.OrganizationsAndVisitAddressDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useOrganizationsAndVisitAddress = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLOrganizationsAndVisitAddressQuery, Types.GQLOrganizationsAndVisitAddressQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OrganizationsAndVisitAddressDocument, options);
};
export type PageOrganizationsAndVisitAddressComp = React.FC<{data?: Types.GQLOrganizationsAndVisitAddressQuery, error?: Apollo.ApolloError}>;
export const ssrOrganizationsAndVisitAddress = {
      getServerPage: getServerPageOrganizationsAndVisitAddress,
      
      usePage: useOrganizationsAndVisitAddress,
    }
export async function getServerPageGetAllRelationAddresses
    (options: Omit<Apollo.QueryOptions<Types.GQLGetAllRelationAddressesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetAllRelationAddressesQuery>({ ...options, query: Operations.GetAllRelationAddressesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetAllRelationAddresses = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetAllRelationAddressesQuery, Types.GQLGetAllRelationAddressesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAllRelationAddressesDocument, options);
};
export type PageGetAllRelationAddressesComp = React.FC<{data?: Types.GQLGetAllRelationAddressesQuery, error?: Apollo.ApolloError}>;
export const ssrGetAllRelationAddresses = {
      getServerPage: getServerPageGetAllRelationAddresses,
      
      usePage: useGetAllRelationAddresses,
    }
export async function getServerPageGetRelatioContactPerson
    (options: Omit<Apollo.QueryOptions<Types.GQLGetRelatioContactPersonQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetRelatioContactPersonQuery>({ ...options, query: Operations.GetRelatioContactPersonDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetRelatioContactPerson = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetRelatioContactPersonQuery, Types.GQLGetRelatioContactPersonQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetRelatioContactPersonDocument, options);
};
export type PageGetRelatioContactPersonComp = React.FC<{data?: Types.GQLGetRelatioContactPersonQuery, error?: Apollo.ApolloError}>;
export const ssrGetRelatioContactPerson = {
      getServerPage: getServerPageGetRelatioContactPerson,
      
      usePage: useGetRelatioContactPerson,
    }
export async function getServerPageGetRelationPerson
    (options: Omit<Apollo.QueryOptions<Types.GQLGetRelationPersonQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetRelationPersonQuery>({ ...options, query: Operations.GetRelationPersonDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetRelationPerson = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetRelationPersonQuery, Types.GQLGetRelationPersonQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetRelationPersonDocument, options);
};
export type PageGetRelationPersonComp = React.FC<{data?: Types.GQLGetRelationPersonQuery, error?: Apollo.ApolloError}>;
export const ssrGetRelationPerson = {
      getServerPage: getServerPageGetRelationPerson,
      
      usePage: useGetRelationPerson,
    }
export async function getServerPageTenantCompanyDetails
    (options: Omit<Apollo.QueryOptions<Types.GQLTenantCompanyDetailsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLTenantCompanyDetailsQuery>({ ...options, query: Operations.TenantCompanyDetailsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useTenantCompanyDetails = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLTenantCompanyDetailsQuery, Types.GQLTenantCompanyDetailsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.TenantCompanyDetailsDocument, options);
};
export type PageTenantCompanyDetailsComp = React.FC<{data?: Types.GQLTenantCompanyDetailsQuery, error?: Apollo.ApolloError}>;
export const ssrTenantCompanyDetails = {
      getServerPage: getServerPageTenantCompanyDetails,
      
      usePage: useTenantCompanyDetails,
    }
export async function getServerPageGetFeatures
    (options: Omit<Apollo.QueryOptions<Types.GQLGetFeaturesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetFeaturesQuery>({ ...options, query: Operations.GetFeaturesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetFeatures = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetFeaturesQuery, Types.GQLGetFeaturesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetFeaturesDocument, options);
};
export type PageGetFeaturesComp = React.FC<{data?: Types.GQLGetFeaturesQuery, error?: Apollo.ApolloError}>;
export const ssrGetFeatures = {
      getServerPage: getServerPageGetFeatures,
      
      usePage: useGetFeatures,
    }
export async function getServerPageGetMenus
    (options: Omit<Apollo.QueryOptions<Types.GQLGetMenusQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetMenusQuery>({ ...options, query: Operations.GetMenusDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetMenus = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetMenusQuery, Types.GQLGetMenusQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetMenusDocument, options);
};
export type PageGetMenusComp = React.FC<{data?: Types.GQLGetMenusQuery, error?: Apollo.ApolloError}>;
export const ssrGetMenus = {
      getServerPage: getServerPageGetMenus,
      
      usePage: useGetMenus,
    }
export async function getServerPageGetModules
    (options: Omit<Apollo.QueryOptions<Types.GQLGetModulesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetModulesQuery>({ ...options, query: Operations.GetModulesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetModules = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetModulesQuery, Types.GQLGetModulesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetModulesDocument, options);
};
export type PageGetModulesComp = React.FC<{data?: Types.GQLGetModulesQuery, error?: Apollo.ApolloError}>;
export const ssrGetModules = {
      getServerPage: getServerPageGetModules,
      
      usePage: useGetModules,
    }
export async function getServerPageGetPermissions
    (options: Omit<Apollo.QueryOptions<Types.GQLGetPermissionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetPermissionsQuery>({ ...options, query: Operations.GetPermissionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetPermissions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetPermissionsQuery, Types.GQLGetPermissionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetPermissionsDocument, options);
};
export type PageGetPermissionsComp = React.FC<{data?: Types.GQLGetPermissionsQuery, error?: Apollo.ApolloError}>;
export const ssrGetPermissions = {
      getServerPage: getServerPageGetPermissions,
      
      usePage: useGetPermissions,
    }
export async function getServerPageGetEmployee
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEmployeeQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEmployeeQuery>({ ...options, query: Operations.GetEmployeeDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEmployee = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEmployeeQuery, Types.GQLGetEmployeeQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEmployeeDocument, options);
};
export type PageGetEmployeeComp = React.FC<{data?: Types.GQLGetEmployeeQuery, error?: Apollo.ApolloError}>;
export const ssrGetEmployee = {
      getServerPage: getServerPageGetEmployee,
      
      usePage: useGetEmployee,
    }
export async function getServerPageGetEmployeesList
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEmployeesListQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEmployeesListQuery>({ ...options, query: Operations.GetEmployeesListDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEmployeesList = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEmployeesListQuery, Types.GQLGetEmployeesListQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEmployeesListDocument, options);
};
export type PageGetEmployeesListComp = React.FC<{data?: Types.GQLGetEmployeesListQuery, error?: Apollo.ApolloError}>;
export const ssrGetEmployeesList = {
      getServerPage: getServerPageGetEmployeesList,
      
      usePage: useGetEmployeesList,
    }
export async function getServerPageGetAllCompetencies
    (options: Omit<Apollo.QueryOptions<Types.GQLGetAllCompetenciesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetAllCompetenciesQuery>({ ...options, query: Operations.GetAllCompetenciesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetAllCompetencies = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetAllCompetenciesQuery, Types.GQLGetAllCompetenciesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAllCompetenciesDocument, options);
};
export type PageGetAllCompetenciesComp = React.FC<{data?: Types.GQLGetAllCompetenciesQuery, error?: Apollo.ApolloError}>;
export const ssrGetAllCompetencies = {
      getServerPage: getServerPageGetAllCompetencies,
      
      usePage: useGetAllCompetencies,
    }
export async function getServerPageGetEmployeeCompetence
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEmployeeCompetenceQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEmployeeCompetenceQuery>({ ...options, query: Operations.GetEmployeeCompetenceDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEmployeeCompetence = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEmployeeCompetenceQuery, Types.GQLGetEmployeeCompetenceQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEmployeeCompetenceDocument, options);
};
export type PageGetEmployeeCompetenceComp = React.FC<{data?: Types.GQLGetEmployeeCompetenceQuery, error?: Apollo.ApolloError}>;
export const ssrGetEmployeeCompetence = {
      getServerPage: getServerPageGetEmployeeCompetence,
      
      usePage: useGetEmployeeCompetence,
    }
export async function getServerPageGetEmployeeCompetencies
    (options: Omit<Apollo.QueryOptions<Types.GQLGetEmployeeCompetenciesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetEmployeeCompetenciesQuery>({ ...options, query: Operations.GetEmployeeCompetenciesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetEmployeeCompetencies = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetEmployeeCompetenciesQuery, Types.GQLGetEmployeeCompetenciesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEmployeeCompetenciesDocument, options);
};
export type PageGetEmployeeCompetenciesComp = React.FC<{data?: Types.GQLGetEmployeeCompetenciesQuery, error?: Apollo.ApolloError}>;
export const ssrGetEmployeeCompetencies = {
      getServerPage: getServerPageGetEmployeeCompetencies,
      
      usePage: useGetEmployeeCompetencies,
    }
export async function getServerPageGetLeaveRegistration
    (options: Omit<Apollo.QueryOptions<Types.GQLGetLeaveRegistrationQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetLeaveRegistrationQuery>({ ...options, query: Operations.GetLeaveRegistrationDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetLeaveRegistration = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetLeaveRegistrationQuery, Types.GQLGetLeaveRegistrationQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetLeaveRegistrationDocument, options);
};
export type PageGetLeaveRegistrationComp = React.FC<{data?: Types.GQLGetLeaveRegistrationQuery, error?: Apollo.ApolloError}>;
export const ssrGetLeaveRegistration = {
      getServerPage: getServerPageGetLeaveRegistration,
      
      usePage: useGetLeaveRegistration,
    }
export async function getServerPageGetLeaveType
    (options: Omit<Apollo.QueryOptions<Types.GQLGetLeaveTypeQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetLeaveTypeQuery>({ ...options, query: Operations.GetLeaveTypeDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetLeaveType = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetLeaveTypeQuery, Types.GQLGetLeaveTypeQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetLeaveTypeDocument, options);
};
export type PageGetLeaveTypeComp = React.FC<{data?: Types.GQLGetLeaveTypeQuery, error?: Apollo.ApolloError}>;
export const ssrGetLeaveType = {
      getServerPage: getServerPageGetLeaveType,
      
      usePage: useGetLeaveType,
    }
export async function getServerPageGetMyLeaveRegistration
    (options: Omit<Apollo.QueryOptions<Types.GQLGetMyLeaveRegistrationQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetMyLeaveRegistrationQuery>({ ...options, query: Operations.GetMyLeaveRegistrationDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetMyLeaveRegistration = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetMyLeaveRegistrationQuery, Types.GQLGetMyLeaveRegistrationQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetMyLeaveRegistrationDocument, options);
};
export type PageGetMyLeaveRegistrationComp = React.FC<{data?: Types.GQLGetMyLeaveRegistrationQuery, error?: Apollo.ApolloError}>;
export const ssrGetMyLeaveRegistration = {
      getServerPage: getServerPageGetMyLeaveRegistration,
      
      usePage: useGetMyLeaveRegistration,
    }
export async function getServerPageMobileLeaveRegistration
    (options: Omit<Apollo.QueryOptions<Types.GQLMobileLeaveRegistrationQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLMobileLeaveRegistrationQuery>({ ...options, query: Operations.MobileLeaveRegistrationDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useMobileLeaveRegistration = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLMobileLeaveRegistrationQuery, Types.GQLMobileLeaveRegistrationQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.MobileLeaveRegistrationDocument, options);
};
export type PageMobileLeaveRegistrationComp = React.FC<{data?: Types.GQLMobileLeaveRegistrationQuery, error?: Apollo.ApolloError}>;
export const ssrMobileLeaveRegistration = {
      getServerPage: getServerPageMobileLeaveRegistration,
      
      usePage: useMobileLeaveRegistration,
    }
export async function getServerPageMyLeaveRegistration
    (options: Omit<Apollo.QueryOptions<Types.GQLMyLeaveRegistrationQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLMyLeaveRegistrationQuery>({ ...options, query: Operations.MyLeaveRegistrationDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useMyLeaveRegistration = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLMyLeaveRegistrationQuery, Types.GQLMyLeaveRegistrationQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.MyLeaveRegistrationDocument, options);
};
export type PageMyLeaveRegistrationComp = React.FC<{data?: Types.GQLMyLeaveRegistrationQuery, error?: Apollo.ApolloError}>;
export const ssrMyLeaveRegistration = {
      getServerPage: getServerPageMyLeaveRegistration,
      
      usePage: useMyLeaveRegistration,
    }
export async function getServerPageGenerateMobilePasswordlessObject
    (options: Omit<Apollo.QueryOptions<Types.GQLGenerateMobilePasswordlessObjectQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGenerateMobilePasswordlessObjectQuery>({ ...options, query: Operations.GenerateMobilePasswordlessObjectDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGenerateMobilePasswordlessObject = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGenerateMobilePasswordlessObjectQuery, Types.GQLGenerateMobilePasswordlessObjectQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GenerateMobilePasswordlessObjectDocument, options);
};
export type PageGenerateMobilePasswordlessObjectComp = React.FC<{data?: Types.GQLGenerateMobilePasswordlessObjectQuery, error?: Apollo.ApolloError}>;
export const ssrGenerateMobilePasswordlessObject = {
      getServerPage: getServerPageGenerateMobilePasswordlessObject,
      
      usePage: useGenerateMobilePasswordlessObject,
    }
export async function getServerPagePortalRoles
    (options: Omit<Apollo.QueryOptions<Types.GQLPortalRolesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLPortalRolesQuery>({ ...options, query: Operations.PortalRolesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const usePortalRoles = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLPortalRolesQuery, Types.GQLPortalRolesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.PortalRolesDocument, options);
};
export type PagePortalRolesComp = React.FC<{data?: Types.GQLPortalRolesQuery, error?: Apollo.ApolloError}>;
export const ssrPortalRoles = {
      getServerPage: getServerPagePortalRoles,
      
      usePage: usePortalRoles,
    }
export async function getServerPagePortalUsers
    (options: Omit<Apollo.QueryOptions<Types.GQLPortalUsersQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLPortalUsersQuery>({ ...options, query: Operations.PortalUsersDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const usePortalUsers = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLPortalUsersQuery, Types.GQLPortalUsersQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.PortalUsersDocument, options);
};
export type PagePortalUsersComp = React.FC<{data?: Types.GQLPortalUsersQuery, error?: Apollo.ApolloError}>;
export const ssrPortalUsers = {
      getServerPage: getServerPagePortalUsers,
      
      usePage: usePortalUsers,
    }
export async function getServerPageGetPlannedActivityDetailsByMomentId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetPlannedActivityDetailsByMomentIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetPlannedActivityDetailsByMomentIdQuery>({ ...options, query: Operations.GetPlannedActivityDetailsByMomentIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetPlannedActivityDetailsByMomentId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetPlannedActivityDetailsByMomentIdQuery, Types.GQLGetPlannedActivityDetailsByMomentIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetPlannedActivityDetailsByMomentIdDocument, options);
};
export type PageGetPlannedActivityDetailsByMomentIdComp = React.FC<{data?: Types.GQLGetPlannedActivityDetailsByMomentIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetPlannedActivityDetailsByMomentId = {
      getServerPage: getServerPageGetPlannedActivityDetailsByMomentId,
      
      usePage: useGetPlannedActivityDetailsByMomentId,
    }
export async function getServerPageGetPlannedMomentsWithFilterOptions
    (options: Omit<Apollo.QueryOptions<Types.GQLGetPlannedMomentsWithFilterOptionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetPlannedMomentsWithFilterOptionsQuery>({ ...options, query: Operations.GetPlannedMomentsWithFilterOptionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetPlannedMomentsWithFilterOptions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetPlannedMomentsWithFilterOptionsQuery, Types.GQLGetPlannedMomentsWithFilterOptionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetPlannedMomentsWithFilterOptionsDocument, options);
};
export type PageGetPlannedMomentsWithFilterOptionsComp = React.FC<{data?: Types.GQLGetPlannedMomentsWithFilterOptionsQuery, error?: Apollo.ApolloError}>;
export const ssrGetPlannedMomentsWithFilterOptions = {
      getServerPage: getServerPageGetPlannedMomentsWithFilterOptions,
      
      usePage: useGetPlannedMomentsWithFilterOptions,
    }
export async function getServerPageGetRepeatConfigurationForMomentByRepeatId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetRepeatConfigurationForMomentByRepeatIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetRepeatConfigurationForMomentByRepeatIdQuery>({ ...options, query: Operations.GetRepeatConfigurationForMomentByRepeatIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetRepeatConfigurationForMomentByRepeatId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetRepeatConfigurationForMomentByRepeatIdQuery, Types.GQLGetRepeatConfigurationForMomentByRepeatIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetRepeatConfigurationForMomentByRepeatIdDocument, options);
};
export type PageGetRepeatConfigurationForMomentByRepeatIdComp = React.FC<{data?: Types.GQLGetRepeatConfigurationForMomentByRepeatIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetRepeatConfigurationForMomentByRepeatId = {
      getServerPage: getServerPageGetRepeatConfigurationForMomentByRepeatId,
      
      usePage: useGetRepeatConfigurationForMomentByRepeatId,
    }
export async function getServerPageGetResourceAvailabilityWithFilterOptions
    (options: Omit<Apollo.QueryOptions<Types.GQLGetResourceAvailabilityWithFilterOptionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetResourceAvailabilityWithFilterOptionsQuery>({ ...options, query: Operations.GetResourceAvailabilityWithFilterOptionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetResourceAvailabilityWithFilterOptions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetResourceAvailabilityWithFilterOptionsQuery, Types.GQLGetResourceAvailabilityWithFilterOptionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetResourceAvailabilityWithFilterOptionsDocument, options);
};
export type PageGetResourceAvailabilityWithFilterOptionsComp = React.FC<{data?: Types.GQLGetResourceAvailabilityWithFilterOptionsQuery, error?: Apollo.ApolloError}>;
export const ssrGetResourceAvailabilityWithFilterOptions = {
      getServerPage: getServerPageGetResourceAvailabilityWithFilterOptions,
      
      usePage: useGetResourceAvailabilityWithFilterOptions,
    }
export async function getServerPageMobileResourceAvailabilityWithFilterOptions
    (options: Omit<Apollo.QueryOptions<Types.GQLMobileResourceAvailabilityWithFilterOptionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLMobileResourceAvailabilityWithFilterOptionsQuery>({ ...options, query: Operations.MobileResourceAvailabilityWithFilterOptionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useMobileResourceAvailabilityWithFilterOptions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLMobileResourceAvailabilityWithFilterOptionsQuery, Types.GQLMobileResourceAvailabilityWithFilterOptionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.MobileResourceAvailabilityWithFilterOptionsDocument, options);
};
export type PageMobileResourceAvailabilityWithFilterOptionsComp = React.FC<{data?: Types.GQLMobileResourceAvailabilityWithFilterOptionsQuery, error?: Apollo.ApolloError}>;
export const ssrMobileResourceAvailabilityWithFilterOptions = {
      getServerPage: getServerPageMobileResourceAvailabilityWithFilterOptions,
      
      usePage: useMobileResourceAvailabilityWithFilterOptions,
    }
export async function getServerPageRepeatMomentsByMomentId
    (options: Omit<Apollo.QueryOptions<Types.GQLRepeatMomentsByMomentIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLRepeatMomentsByMomentIdQuery>({ ...options, query: Operations.RepeatMomentsByMomentIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRepeatMomentsByMomentId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLRepeatMomentsByMomentIdQuery, Types.GQLRepeatMomentsByMomentIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.RepeatMomentsByMomentIdDocument, options);
};
export type PageRepeatMomentsByMomentIdComp = React.FC<{data?: Types.GQLRepeatMomentsByMomentIdQuery, error?: Apollo.ApolloError}>;
export const ssrRepeatMomentsByMomentId = {
      getServerPage: getServerPageRepeatMomentsByMomentId,
      
      usePage: useRepeatMomentsByMomentId,
    }
export async function getServerPageGetProject
    (options: Omit<Apollo.QueryOptions<Types.GQLGetProjectQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetProjectQuery>({ ...options, query: Operations.GetProjectDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetProject = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetProjectQuery, Types.GQLGetProjectQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetProjectDocument, options);
};
export type PageGetProjectComp = React.FC<{data?: Types.GQLGetProjectQuery, error?: Apollo.ApolloError}>;
export const ssrGetProject = {
      getServerPage: getServerPageGetProject,
      
      usePage: useGetProject,
    }
export async function getServerPageProjectByOrganizationId
    (options: Omit<Apollo.QueryOptions<Types.GQLProjectByOrganizationIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLProjectByOrganizationIdQuery>({ ...options, query: Operations.ProjectByOrganizationIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProjectByOrganizationId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLProjectByOrganizationIdQuery, Types.GQLProjectByOrganizationIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ProjectByOrganizationIdDocument, options);
};
export type PageProjectByOrganizationIdComp = React.FC<{data?: Types.GQLProjectByOrganizationIdQuery, error?: Apollo.ApolloError}>;
export const ssrProjectByOrganizationId = {
      getServerPage: getServerPageProjectByOrganizationId,
      
      usePage: useProjectByOrganizationId,
    }
export async function getServerPageProjectCategory
    (options: Omit<Apollo.QueryOptions<Types.GQLProjectCategoryQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLProjectCategoryQuery>({ ...options, query: Operations.ProjectCategoryDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProjectCategory = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLProjectCategoryQuery, Types.GQLProjectCategoryQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ProjectCategoryDocument, options);
};
export type PageProjectCategoryComp = React.FC<{data?: Types.GQLProjectCategoryQuery, error?: Apollo.ApolloError}>;
export const ssrProjectCategory = {
      getServerPage: getServerPageProjectCategory,
      
      usePage: useProjectCategory,
    }
export async function getServerPageGetProjectHours
    (options: Omit<Apollo.QueryOptions<Types.GQLGetProjectHoursQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetProjectHoursQuery>({ ...options, query: Operations.GetProjectHoursDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetProjectHours = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetProjectHoursQuery, Types.GQLGetProjectHoursQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetProjectHoursDocument, options);
};
export type PageGetProjectHoursComp = React.FC<{data?: Types.GQLGetProjectHoursQuery, error?: Apollo.ApolloError}>;
export const ssrGetProjectHours = {
      getServerPage: getServerPageGetProjectHours,
      
      usePage: useGetProjectHours,
    }
export async function getServerPageGetProjectProperties
    (options: Omit<Apollo.QueryOptions<Types.GQLGetProjectPropertiesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetProjectPropertiesQuery>({ ...options, query: Operations.GetProjectPropertiesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetProjectProperties = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetProjectPropertiesQuery, Types.GQLGetProjectPropertiesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetProjectPropertiesDocument, options);
};
export type PageGetProjectPropertiesComp = React.FC<{data?: Types.GQLGetProjectPropertiesQuery, error?: Apollo.ApolloError}>;
export const ssrGetProjectProperties = {
      getServerPage: getServerPageGetProjectProperties,
      
      usePage: useGetProjectProperties,
    }
export async function getServerPageProjectTotalHours
    (options: Omit<Apollo.QueryOptions<Types.GQLProjectTotalHoursQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLProjectTotalHoursQuery>({ ...options, query: Operations.ProjectTotalHoursDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProjectTotalHours = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLProjectTotalHoursQuery, Types.GQLProjectTotalHoursQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ProjectTotalHoursDocument, options);
};
export type PageProjectTotalHoursComp = React.FC<{data?: Types.GQLProjectTotalHoursQuery, error?: Apollo.ApolloError}>;
export const ssrProjectTotalHours = {
      getServerPage: getServerPageProjectTotalHours,
      
      usePage: useProjectTotalHours,
    }
export async function getServerPageProjectType
    (options: Omit<Apollo.QueryOptions<Types.GQLProjectTypeQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLProjectTypeQuery>({ ...options, query: Operations.ProjectTypeDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProjectType = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLProjectTypeQuery, Types.GQLProjectTypeQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ProjectTypeDocument, options);
};
export type PageProjectTypeComp = React.FC<{data?: Types.GQLProjectTypeQuery, error?: Apollo.ApolloError}>;
export const ssrProjectType = {
      getServerPage: getServerPageProjectType,
      
      usePage: useProjectType,
    }
export async function getServerPageGetProjects
    (options: Omit<Apollo.QueryOptions<Types.GQLGetProjectsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetProjectsQuery>({ ...options, query: Operations.GetProjectsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetProjects = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetProjectsQuery, Types.GQLGetProjectsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetProjectsDocument, options);
};
export type PageGetProjectsComp = React.FC<{data?: Types.GQLGetProjectsQuery, error?: Apollo.ApolloError}>;
export const ssrGetProjects = {
      getServerPage: getServerPageGetProjects,
      
      usePage: useGetProjects,
    }
export async function getServerPageGetTotalHoursByEmployeeProject
    (options: Omit<Apollo.QueryOptions<Types.GQLGetTotalHoursByEmployeeProjectQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetTotalHoursByEmployeeProjectQuery>({ ...options, query: Operations.GetTotalHoursByEmployeeProjectDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetTotalHoursByEmployeeProject = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetTotalHoursByEmployeeProjectQuery, Types.GQLGetTotalHoursByEmployeeProjectQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetTotalHoursByEmployeeProjectDocument, options);
};
export type PageGetTotalHoursByEmployeeProjectComp = React.FC<{data?: Types.GQLGetTotalHoursByEmployeeProjectQuery, error?: Apollo.ApolloError}>;
export const ssrGetTotalHoursByEmployeeProject = {
      getServerPage: getServerPageGetTotalHoursByEmployeeProject,
      
      usePage: useGetTotalHoursByEmployeeProject,
    }
export async function getServerPageGetProductsWithFilterOptions
    (options: Omit<Apollo.QueryOptions<Types.GQLGetProductsWithFilterOptionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetProductsWithFilterOptionsQuery>({ ...options, query: Operations.GetProductsWithFilterOptionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetProductsWithFilterOptions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetProductsWithFilterOptionsQuery, Types.GQLGetProductsWithFilterOptionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetProductsWithFilterOptionsDocument, options);
};
export type PageGetProductsWithFilterOptionsComp = React.FC<{data?: Types.GQLGetProductsWithFilterOptionsQuery, error?: Apollo.ApolloError}>;
export const ssrGetProductsWithFilterOptions = {
      getServerPage: getServerPageGetProductsWithFilterOptions,
      
      usePage: useGetProductsWithFilterOptions,
    }
export async function getServerPageGetQuotationById
    (options: Omit<Apollo.QueryOptions<Types.GQLGetQuotationByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetQuotationByIdQuery>({ ...options, query: Operations.GetQuotationByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetQuotationById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetQuotationByIdQuery, Types.GQLGetQuotationByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetQuotationByIdDocument, options);
};
export type PageGetQuotationByIdComp = React.FC<{data?: Types.GQLGetQuotationByIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetQuotationById = {
      getServerPage: getServerPageGetQuotationById,
      
      usePage: useGetQuotationById,
    }
export async function getServerPageGetQuotationLibraryCategories
    (options: Omit<Apollo.QueryOptions<Types.GQLGetQuotationLibraryCategoriesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetQuotationLibraryCategoriesQuery>({ ...options, query: Operations.GetQuotationLibraryCategoriesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetQuotationLibraryCategories = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetQuotationLibraryCategoriesQuery, Types.GQLGetQuotationLibraryCategoriesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetQuotationLibraryCategoriesDocument, options);
};
export type PageGetQuotationLibraryCategoriesComp = React.FC<{data?: Types.GQLGetQuotationLibraryCategoriesQuery, error?: Apollo.ApolloError}>;
export const ssrGetQuotationLibraryCategories = {
      getServerPage: getServerPageGetQuotationLibraryCategories,
      
      usePage: useGetQuotationLibraryCategories,
    }
export async function getServerPageGetQuotationLibraryTextById
    (options: Omit<Apollo.QueryOptions<Types.GQLGetQuotationLibraryTextByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetQuotationLibraryTextByIdQuery>({ ...options, query: Operations.GetQuotationLibraryTextByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetQuotationLibraryTextById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetQuotationLibraryTextByIdQuery, Types.GQLGetQuotationLibraryTextByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetQuotationLibraryTextByIdDocument, options);
};
export type PageGetQuotationLibraryTextByIdComp = React.FC<{data?: Types.GQLGetQuotationLibraryTextByIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetQuotationLibraryTextById = {
      getServerPage: getServerPageGetQuotationLibraryTextById,
      
      usePage: useGetQuotationLibraryTextById,
    }
export async function getServerPageGetQuotationLinesByQuotationId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetQuotationLinesByQuotationIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetQuotationLinesByQuotationIdQuery>({ ...options, query: Operations.GetQuotationLinesByQuotationIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetQuotationLinesByQuotationId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetQuotationLinesByQuotationIdQuery, Types.GQLGetQuotationLinesByQuotationIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetQuotationLinesByQuotationIdDocument, options);
};
export type PageGetQuotationLinesByQuotationIdComp = React.FC<{data?: Types.GQLGetQuotationLinesByQuotationIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetQuotationLinesByQuotationId = {
      getServerPage: getServerPageGetQuotationLinesByQuotationId,
      
      usePage: useGetQuotationLinesByQuotationId,
    }
export async function getServerPageGetQuotationTemplateLinesByTemplateId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetQuotationTemplateLinesByTemplateIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetQuotationTemplateLinesByTemplateIdQuery>({ ...options, query: Operations.GetQuotationTemplateLinesByTemplateIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetQuotationTemplateLinesByTemplateId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetQuotationTemplateLinesByTemplateIdQuery, Types.GQLGetQuotationTemplateLinesByTemplateIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetQuotationTemplateLinesByTemplateIdDocument, options);
};
export type PageGetQuotationTemplateLinesByTemplateIdComp = React.FC<{data?: Types.GQLGetQuotationTemplateLinesByTemplateIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetQuotationTemplateLinesByTemplateId = {
      getServerPage: getServerPageGetQuotationTemplateLinesByTemplateId,
      
      usePage: useGetQuotationTemplateLinesByTemplateId,
    }
export async function getServerPageGetQuotationTemplates
    (options: Omit<Apollo.QueryOptions<Types.GQLGetQuotationTemplatesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetQuotationTemplatesQuery>({ ...options, query: Operations.GetQuotationTemplatesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetQuotationTemplates = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetQuotationTemplatesQuery, Types.GQLGetQuotationTemplatesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetQuotationTemplatesDocument, options);
};
export type PageGetQuotationTemplatesComp = React.FC<{data?: Types.GQLGetQuotationTemplatesQuery, error?: Apollo.ApolloError}>;
export const ssrGetQuotationTemplates = {
      getServerPage: getServerPageGetQuotationTemplates,
      
      usePage: useGetQuotationTemplates,
    }
export async function getServerPageGetQuotationTextByQuotationId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetQuotationTextByQuotationIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetQuotationTextByQuotationIdQuery>({ ...options, query: Operations.GetQuotationTextByQuotationIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetQuotationTextByQuotationId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetQuotationTextByQuotationIdQuery, Types.GQLGetQuotationTextByQuotationIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetQuotationTextByQuotationIdDocument, options);
};
export type PageGetQuotationTextByQuotationIdComp = React.FC<{data?: Types.GQLGetQuotationTextByQuotationIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetQuotationTextByQuotationId = {
      getServerPage: getServerPageGetQuotationTextByQuotationId,
      
      usePage: useGetQuotationTextByQuotationId,
    }
export async function getServerPageGetQuotationsWithFilterOptions
    (options: Omit<Apollo.QueryOptions<Types.GQLGetQuotationsWithFilterOptionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetQuotationsWithFilterOptionsQuery>({ ...options, query: Operations.GetQuotationsWithFilterOptionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetQuotationsWithFilterOptions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetQuotationsWithFilterOptionsQuery, Types.GQLGetQuotationsWithFilterOptionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetQuotationsWithFilterOptionsDocument, options);
};
export type PageGetQuotationsWithFilterOptionsComp = React.FC<{data?: Types.GQLGetQuotationsWithFilterOptionsQuery, error?: Apollo.ApolloError}>;
export const ssrGetQuotationsWithFilterOptions = {
      getServerPage: getServerPageGetQuotationsWithFilterOptions,
      
      usePage: useGetQuotationsWithFilterOptions,
    }
export async function getServerPageQuotationLibraryTextWithFiltersOptions
    (options: Omit<Apollo.QueryOptions<Types.GQLQuotationLibraryTextWithFiltersOptionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLQuotationLibraryTextWithFiltersOptionsQuery>({ ...options, query: Operations.QuotationLibraryTextWithFiltersOptionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useQuotationLibraryTextWithFiltersOptions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLQuotationLibraryTextWithFiltersOptionsQuery, Types.GQLQuotationLibraryTextWithFiltersOptionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.QuotationLibraryTextWithFiltersOptionsDocument, options);
};
export type PageQuotationLibraryTextWithFiltersOptionsComp = React.FC<{data?: Types.GQLQuotationLibraryTextWithFiltersOptionsQuery, error?: Apollo.ApolloError}>;
export const ssrQuotationLibraryTextWithFiltersOptions = {
      getServerPage: getServerPageQuotationLibraryTextWithFiltersOptions,
      
      usePage: useQuotationLibraryTextWithFiltersOptions,
    }
export async function getServerPageCheckExisitingContactPerson
    (options: Omit<Apollo.QueryOptions<Types.GQLCheckExisitingContactPersonQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCheckExisitingContactPersonQuery>({ ...options, query: Operations.CheckExisitingContactPersonDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCheckExisitingContactPerson = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCheckExisitingContactPersonQuery, Types.GQLCheckExisitingContactPersonQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CheckExisitingContactPersonDocument, options);
};
export type PageCheckExisitingContactPersonComp = React.FC<{data?: Types.GQLCheckExisitingContactPersonQuery, error?: Apollo.ApolloError}>;
export const ssrCheckExisitingContactPerson = {
      getServerPage: getServerPageCheckExisitingContactPerson,
      
      usePage: useCheckExisitingContactPerson,
    }
export async function getServerPageCheckExistingOrganization
    (options: Omit<Apollo.QueryOptions<Types.GQLCheckExistingOrganizationQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLCheckExistingOrganizationQuery>({ ...options, query: Operations.CheckExistingOrganizationDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCheckExistingOrganization = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLCheckExistingOrganizationQuery, Types.GQLCheckExistingOrganizationQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CheckExistingOrganizationDocument, options);
};
export type PageCheckExistingOrganizationComp = React.FC<{data?: Types.GQLCheckExistingOrganizationQuery, error?: Apollo.ApolloError}>;
export const ssrCheckExistingOrganization = {
      getServerPage: getServerPageCheckExistingOrganization,
      
      usePage: useCheckExistingOrganization,
    }
export async function getServerPageGetCocMainLocation
    (options: Omit<Apollo.QueryOptions<Types.GQLGetCocMainLocationQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetCocMainLocationQuery>({ ...options, query: Operations.GetCocMainLocationDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetCocMainLocation = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetCocMainLocationQuery, Types.GQLGetCocMainLocationQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetCocMainLocationDocument, options);
};
export type PageGetCocMainLocationComp = React.FC<{data?: Types.GQLGetCocMainLocationQuery, error?: Apollo.ApolloError}>;
export const ssrGetCocMainLocation = {
      getServerPage: getServerPageGetCocMainLocation,
      
      usePage: useGetCocMainLocation,
    }
export async function getServerPageGetOrganizationAddress
    (options: Omit<Apollo.QueryOptions<Types.GQLGetOrganizationAddressQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetOrganizationAddressQuery>({ ...options, query: Operations.GetOrganizationAddressDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetOrganizationAddress = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetOrganizationAddressQuery, Types.GQLGetOrganizationAddressQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOrganizationAddressDocument, options);
};
export type PageGetOrganizationAddressComp = React.FC<{data?: Types.GQLGetOrganizationAddressQuery, error?: Apollo.ApolloError}>;
export const ssrGetOrganizationAddress = {
      getServerPage: getServerPageGetOrganizationAddress,
      
      usePage: useGetOrganizationAddress,
    }
export async function getServerPageGetOrganizationAddressType
    (options: Omit<Apollo.QueryOptions<Types.GQLGetOrganizationAddressTypeQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetOrganizationAddressTypeQuery>({ ...options, query: Operations.GetOrganizationAddressTypeDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetOrganizationAddressType = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetOrganizationAddressTypeQuery, Types.GQLGetOrganizationAddressTypeQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOrganizationAddressTypeDocument, options);
};
export type PageGetOrganizationAddressTypeComp = React.FC<{data?: Types.GQLGetOrganizationAddressTypeQuery, error?: Apollo.ApolloError}>;
export const ssrGetOrganizationAddressType = {
      getServerPage: getServerPageGetOrganizationAddressType,
      
      usePage: useGetOrganizationAddressType,
    }
export async function getServerPageGetOrganizationDetailsWithMainAddressByOrganizationId
    (options: Omit<Apollo.QueryOptions<Types.GQLGetOrganizationDetailsWithMainAddressByOrganizationIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetOrganizationDetailsWithMainAddressByOrganizationIdQuery>({ ...options, query: Operations.GetOrganizationDetailsWithMainAddressByOrganizationIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetOrganizationDetailsWithMainAddressByOrganizationId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetOrganizationDetailsWithMainAddressByOrganizationIdQuery, Types.GQLGetOrganizationDetailsWithMainAddressByOrganizationIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOrganizationDetailsWithMainAddressByOrganizationIdDocument, options);
};
export type PageGetOrganizationDetailsWithMainAddressByOrganizationIdComp = React.FC<{data?: Types.GQLGetOrganizationDetailsWithMainAddressByOrganizationIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetOrganizationDetailsWithMainAddressByOrganizationId = {
      getServerPage: getServerPageGetOrganizationDetailsWithMainAddressByOrganizationId,
      
      usePage: useGetOrganizationDetailsWithMainAddressByOrganizationId,
    }
export async function getServerPageLatestOrganizationConfiguration
    (options: Omit<Apollo.QueryOptions<Types.GQLLatestOrganizationConfigurationQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLLatestOrganizationConfigurationQuery>({ ...options, query: Operations.LatestOrganizationConfigurationDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useLatestOrganizationConfiguration = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLLatestOrganizationConfigurationQuery, Types.GQLLatestOrganizationConfigurationQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.LatestOrganizationConfigurationDocument, options);
};
export type PageLatestOrganizationConfigurationComp = React.FC<{data?: Types.GQLLatestOrganizationConfigurationQuery, error?: Apollo.ApolloError}>;
export const ssrLatestOrganizationConfiguration = {
      getServerPage: getServerPageLatestOrganizationConfiguration,
      
      usePage: useLatestOrganizationConfiguration,
    }
export async function getServerPageGetRelationAddresses
    (options: Omit<Apollo.QueryOptions<Types.GQLGetRelationAddressesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetRelationAddressesQuery>({ ...options, query: Operations.GetRelationAddressesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetRelationAddresses = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetRelationAddressesQuery, Types.GQLGetRelationAddressesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetRelationAddressesDocument, options);
};
export type PageGetRelationAddressesComp = React.FC<{data?: Types.GQLGetRelationAddressesQuery, error?: Apollo.ApolloError}>;
export const ssrGetRelationAddresses = {
      getServerPage: getServerPageGetRelationAddresses,
      
      usePage: useGetRelationAddresses,
    }
export async function getServerPageGetOrganizationConfiguration
    (options: Omit<Apollo.QueryOptions<Types.GQLGetOrganizationConfigurationQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetOrganizationConfigurationQuery>({ ...options, query: Operations.GetOrganizationConfigurationDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetOrganizationConfiguration = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetOrganizationConfigurationQuery, Types.GQLGetOrganizationConfigurationQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOrganizationConfigurationDocument, options);
};
export type PageGetOrganizationConfigurationComp = React.FC<{data?: Types.GQLGetOrganizationConfigurationQuery, error?: Apollo.ApolloError}>;
export const ssrGetOrganizationConfiguration = {
      getServerPage: getServerPageGetOrganizationConfiguration,
      
      usePage: useGetOrganizationConfiguration,
    }
export async function getServerPageGetOrganizationHierArchy
    (options: Omit<Apollo.QueryOptions<Types.GQLGetOrganizationHierArchyQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetOrganizationHierArchyQuery>({ ...options, query: Operations.GetOrganizationHierArchyDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetOrganizationHierArchy = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetOrganizationHierArchyQuery, Types.GQLGetOrganizationHierArchyQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOrganizationHierArchyDocument, options);
};
export type PageGetOrganizationHierArchyComp = React.FC<{data?: Types.GQLGetOrganizationHierArchyQuery, error?: Apollo.ApolloError}>;
export const ssrGetOrganizationHierArchy = {
      getServerPage: getServerPageGetOrganizationHierArchy,
      
      usePage: useGetOrganizationHierArchy,
    }
export async function getServerPageOrganizationHierarchyWithAddresses
    (options: Omit<Apollo.QueryOptions<Types.GQLOrganizationHierarchyWithAddressesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLOrganizationHierarchyWithAddressesQuery>({ ...options, query: Operations.OrganizationHierarchyWithAddressesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useOrganizationHierarchyWithAddresses = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLOrganizationHierarchyWithAddressesQuery, Types.GQLOrganizationHierarchyWithAddressesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OrganizationHierarchyWithAddressesDocument, options);
};
export type PageOrganizationHierarchyWithAddressesComp = React.FC<{data?: Types.GQLOrganizationHierarchyWithAddressesQuery, error?: Apollo.ApolloError}>;
export const ssrOrganizationHierarchyWithAddresses = {
      getServerPage: getServerPageOrganizationHierarchyWithAddresses,
      
      usePage: useOrganizationHierarchyWithAddresses,
    }
export async function getServerPageOrganizationNameById
    (options: Omit<Apollo.QueryOptions<Types.GQLOrganizationNameByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLOrganizationNameByIdQuery>({ ...options, query: Operations.OrganizationNameByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useOrganizationNameById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLOrganizationNameByIdQuery, Types.GQLOrganizationNameByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OrganizationNameByIdDocument, options);
};
export type PageOrganizationNameByIdComp = React.FC<{data?: Types.GQLOrganizationNameByIdQuery, error?: Apollo.ApolloError}>;
export const ssrOrganizationNameById = {
      getServerPage: getServerPageOrganizationNameById,
      
      usePage: useOrganizationNameById,
    }
export async function getServerPagePersonById
    (options: Omit<Apollo.QueryOptions<Types.GQLPersonByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLPersonByIdQuery>({ ...options, query: Operations.PersonByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const usePersonById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLPersonByIdQuery, Types.GQLPersonByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.PersonByIdDocument, options);
};
export type PagePersonByIdComp = React.FC<{data?: Types.GQLPersonByIdQuery, error?: Apollo.ApolloError}>;
export const ssrPersonById = {
      getServerPage: getServerPagePersonById,
      
      usePage: usePersonById,
    }
export async function getServerPageGetPersonsConnectedToOrganization
    (options: Omit<Apollo.QueryOptions<Types.GQLGetPersonsConnectedToOrganizationQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetPersonsConnectedToOrganizationQuery>({ ...options, query: Operations.GetPersonsConnectedToOrganizationDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetPersonsConnectedToOrganization = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetPersonsConnectedToOrganizationQuery, Types.GQLGetPersonsConnectedToOrganizationQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetPersonsConnectedToOrganizationDocument, options);
};
export type PageGetPersonsConnectedToOrganizationComp = React.FC<{data?: Types.GQLGetPersonsConnectedToOrganizationQuery, error?: Apollo.ApolloError}>;
export const ssrGetPersonsConnectedToOrganization = {
      getServerPage: getServerPageGetPersonsConnectedToOrganization,
      
      usePage: useGetPersonsConnectedToOrganization,
    }
export async function getServerPageRelationAddressesByTenant
    (options: Omit<Apollo.QueryOptions<Types.GQLRelationAddressesByTenantQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLRelationAddressesByTenantQuery>({ ...options, query: Operations.RelationAddressesByTenantDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRelationAddressesByTenant = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLRelationAddressesByTenantQuery, Types.GQLRelationAddressesByTenantQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.RelationAddressesByTenantDocument, options);
};
export type PageRelationAddressesByTenantComp = React.FC<{data?: Types.GQLRelationAddressesByTenantQuery, error?: Apollo.ApolloError}>;
export const ssrRelationAddressesByTenant = {
      getServerPage: getServerPageRelationAddressesByTenant,
      
      usePage: useRelationAddressesByTenant,
    }
export async function getServerPageRelationEntityConfig
    (options: Omit<Apollo.QueryOptions<Types.GQLRelationEntityConfigQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLRelationEntityConfigQuery>({ ...options, query: Operations.RelationEntityConfigDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRelationEntityConfig = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLRelationEntityConfigQuery, Types.GQLRelationEntityConfigQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.RelationEntityConfigDocument, options);
};
export type PageRelationEntityConfigComp = React.FC<{data?: Types.GQLRelationEntityConfigQuery, error?: Apollo.ApolloError}>;
export const ssrRelationEntityConfig = {
      getServerPage: getServerPageRelationEntityConfig,
      
      usePage: useRelationEntityConfig,
    }
export async function getServerPageGetAllRelationList
    (options: Omit<Apollo.QueryOptions<Types.GQLGetAllRelationListQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetAllRelationListQuery>({ ...options, query: Operations.GetAllRelationListDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetAllRelationList = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetAllRelationListQuery, Types.GQLGetAllRelationListQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAllRelationListDocument, options);
};
export type PageGetAllRelationListComp = React.FC<{data?: Types.GQLGetAllRelationListQuery, error?: Apollo.ApolloError}>;
export const ssrGetAllRelationList = {
      getServerPage: getServerPageGetAllRelationList,
      
      usePage: useGetAllRelationList,
    }
export async function getServerPageGetHoursWithFilterOptions
    (options: Omit<Apollo.QueryOptions<Types.GQLGetHoursWithFilterOptionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetHoursWithFilterOptionsQuery>({ ...options, query: Operations.GetHoursWithFilterOptionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetHoursWithFilterOptions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetHoursWithFilterOptionsQuery, Types.GQLGetHoursWithFilterOptionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetHoursWithFilterOptionsDocument, options);
};
export type PageGetHoursWithFilterOptionsComp = React.FC<{data?: Types.GQLGetHoursWithFilterOptionsQuery, error?: Apollo.ApolloError}>;
export const ssrGetHoursWithFilterOptions = {
      getServerPage: getServerPageGetHoursWithFilterOptions,
      
      usePage: useGetHoursWithFilterOptions,
    }
export async function getServerPageGetTimesheet
    (options: Omit<Apollo.QueryOptions<Types.GQLGetTimesheetQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetTimesheetQuery>({ ...options, query: Operations.GetTimesheetDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetTimesheet = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetTimesheetQuery, Types.GQLGetTimesheetQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetTimesheetDocument, options);
};
export type PageGetTimesheetComp = React.FC<{data?: Types.GQLGetTimesheetQuery, error?: Apollo.ApolloError}>;
export const ssrGetTimesheet = {
      getServerPage: getServerPageGetTimesheet,
      
      usePage: useGetTimesheet,
    }
export async function getServerPageTimesheetsExport
    (options: Omit<Apollo.QueryOptions<Types.GQLTimesheetsExportQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLTimesheetsExportQuery>({ ...options, query: Operations.TimesheetsExportDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useTimesheetsExport = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLTimesheetsExportQuery, Types.GQLTimesheetsExportQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.TimesheetsExportDocument, options);
};
export type PageTimesheetsExportComp = React.FC<{data?: Types.GQLTimesheetsExportQuery, error?: Apollo.ApolloError}>;
export const ssrTimesheetsExport = {
      getServerPage: getServerPageTimesheetsExport,
      
      usePage: useTimesheetsExport,
    }
export async function getServerPageGetTimesheetsByProjectEmployee
    (options: Omit<Apollo.QueryOptions<Types.GQLGetTimesheetsByProjectEmployeeQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetTimesheetsByProjectEmployeeQuery>({ ...options, query: Operations.GetTimesheetsByProjectEmployeeDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetTimesheetsByProjectEmployee = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetTimesheetsByProjectEmployeeQuery, Types.GQLGetTimesheetsByProjectEmployeeQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetTimesheetsByProjectEmployeeDocument, options);
};
export type PageGetTimesheetsByProjectEmployeeComp = React.FC<{data?: Types.GQLGetTimesheetsByProjectEmployeeQuery, error?: Apollo.ApolloError}>;
export const ssrGetTimesheetsByProjectEmployee = {
      getServerPage: getServerPageGetTimesheetsByProjectEmployee,
      
      usePage: useGetTimesheetsByProjectEmployee,
    }
export async function getServerPageGetTotalHoursByOrganizationEmployeeProject
    (options: Omit<Apollo.QueryOptions<Types.GQLGetTotalHoursByOrganizationEmployeeProjectQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetTotalHoursByOrganizationEmployeeProjectQuery>({ ...options, query: Operations.GetTotalHoursByOrganizationEmployeeProjectDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetTotalHoursByOrganizationEmployeeProject = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetTotalHoursByOrganizationEmployeeProjectQuery, Types.GQLGetTotalHoursByOrganizationEmployeeProjectQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetTotalHoursByOrganizationEmployeeProjectDocument, options);
};
export type PageGetTotalHoursByOrganizationEmployeeProjectComp = React.FC<{data?: Types.GQLGetTotalHoursByOrganizationEmployeeProjectQuery, error?: Apollo.ApolloError}>;
export const ssrGetTotalHoursByOrganizationEmployeeProject = {
      getServerPage: getServerPageGetTotalHoursByOrganizationEmployeeProject,
      
      usePage: useGetTotalHoursByOrganizationEmployeeProject,
    }
export async function getServerPageGetHoursToBeInvoiced
    (options: Omit<Apollo.QueryOptions<Types.GQLGetHoursToBeInvoicedQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetHoursToBeInvoicedQuery>({ ...options, query: Operations.GetHoursToBeInvoicedDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetHoursToBeInvoiced = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetHoursToBeInvoicedQuery, Types.GQLGetHoursToBeInvoicedQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetHoursToBeInvoicedDocument, options);
};
export type PageGetHoursToBeInvoicedComp = React.FC<{data?: Types.GQLGetHoursToBeInvoicedQuery, error?: Apollo.ApolloError}>;
export const ssrGetHoursToBeInvoiced = {
      getServerPage: getServerPageGetHoursToBeInvoiced,
      
      usePage: useGetHoursToBeInvoiced,
    }
export async function getServerPageGetHoursToBeValidated
    (options: Omit<Apollo.QueryOptions<Types.GQLGetHoursToBeValidatedQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetHoursToBeValidatedQuery>({ ...options, query: Operations.GetHoursToBeValidatedDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetHoursToBeValidated = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetHoursToBeValidatedQuery, Types.GQLGetHoursToBeValidatedQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetHoursToBeValidatedDocument, options);
};
export type PageGetHoursToBeValidatedComp = React.FC<{data?: Types.GQLGetHoursToBeValidatedQuery, error?: Apollo.ApolloError}>;
export const ssrGetHoursToBeValidated = {
      getServerPage: getServerPageGetHoursToBeValidated,
      
      usePage: useGetHoursToBeValidated,
    }
export async function getServerPageGetMappedPermissions
    (options: Omit<Apollo.QueryOptions<Types.GQLGetMappedPermissionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetMappedPermissionsQuery>({ ...options, query: Operations.GetMappedPermissionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetMappedPermissions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetMappedPermissionsQuery, Types.GQLGetMappedPermissionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetMappedPermissionsDocument, options);
};
export type PageGetMappedPermissionsComp = React.FC<{data?: Types.GQLGetMappedPermissionsQuery, error?: Apollo.ApolloError}>;
export const ssrGetMappedPermissions = {
      getServerPage: getServerPageGetMappedPermissions,
      
      usePage: useGetMappedPermissions,
    }
export async function getServerPageGetRoles
    (options: Omit<Apollo.QueryOptions<Types.GQLGetRolesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetRolesQuery>({ ...options, query: Operations.GetRolesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetRoles = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetRolesQuery, Types.GQLGetRolesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetRolesDocument, options);
};
export type PageGetRolesComp = React.FC<{data?: Types.GQLGetRolesQuery, error?: Apollo.ApolloError}>;
export const ssrGetRoles = {
      getServerPage: getServerPageGetRoles,
      
      usePage: useGetRoles,
    }
export async function getServerPageGetUnmappedPermissions
    (options: Omit<Apollo.QueryOptions<Types.GQLGetUnmappedPermissionsQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetUnmappedPermissionsQuery>({ ...options, query: Operations.GetUnmappedPermissionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetUnmappedPermissions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetUnmappedPermissionsQuery, Types.GQLGetUnmappedPermissionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetUnmappedPermissionsDocument, options);
};
export type PageGetUnmappedPermissionsComp = React.FC<{data?: Types.GQLGetUnmappedPermissionsQuery, error?: Apollo.ApolloError}>;
export const ssrGetUnmappedPermissions = {
      getServerPage: getServerPageGetUnmappedPermissions,
      
      usePage: useGetUnmappedPermissions,
    }
export async function getServerPageGetUserAccountById
    (options: Omit<Apollo.QueryOptions<Types.GQLGetUserAccountByIdQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetUserAccountByIdQuery>({ ...options, query: Operations.GetUserAccountByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetUserAccountById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetUserAccountByIdQuery, Types.GQLGetUserAccountByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetUserAccountByIdDocument, options);
};
export type PageGetUserAccountByIdComp = React.FC<{data?: Types.GQLGetUserAccountByIdQuery, error?: Apollo.ApolloError}>;
export const ssrGetUserAccountById = {
      getServerPage: getServerPageGetUserAccountById,
      
      usePage: useGetUserAccountById,
    }
export async function getServerPageGetUser
    (options: Omit<Apollo.QueryOptions<Types.GQLGetUserQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetUserQuery>({ ...options, query: Operations.GetUserDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetUser = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetUserQuery, Types.GQLGetUserQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetUserDocument, options);
};
export type PageGetUserComp = React.FC<{data?: Types.GQLGetUserQuery, error?: Apollo.ApolloError}>;
export const ssrGetUser = {
      getServerPage: getServerPageGetUser,
      
      usePage: useGetUser,
    }
export async function getServerPageGetUsers
    (options: Omit<Apollo.QueryOptions<Types.GQLGetUsersQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLGetUsersQuery>({ ...options, query: Operations.GetUsersDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetUsers = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLGetUsersQuery, Types.GQLGetUsersQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetUsersDocument, options);
};
export type PageGetUsersComp = React.FC<{data?: Types.GQLGetUsersQuery, error?: Apollo.ApolloError}>;
export const ssrGetUsers = {
      getServerPage: getServerPageGetUsers,
      
      usePage: useGetUsers,
    }
export async function getServerPageEnabledFeatures
    (options: Omit<Apollo.QueryOptions<Types.GQLEnabledFeaturesQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GQLEnabledFeaturesQuery>({ ...options, query: Operations.EnabledFeaturesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useEnabledFeatures = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GQLEnabledFeaturesQuery, Types.GQLEnabledFeaturesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.EnabledFeaturesDocument, options);
};
export type PageEnabledFeaturesComp = React.FC<{data?: Types.GQLEnabledFeaturesQuery, error?: Apollo.ApolloError}>;
export const ssrEnabledFeatures = {
      getServerPage: getServerPageEnabledFeatures,
      
      usePage: useEnabledFeatures,
    }